/** Font-Family */

@font-face {
	font-family: 'robotobold';
	src: url('/assets/fonts/Roboto/Roboto-bold/Roboto-Bold.woff2') format('woff2'), url('/assets/fonts/Roboto/Roboto-bold/Roboto-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'robotoregular';
	src: url('/assets/fonts/Roboto/Roboto-regular/Roboto-Regular.woff2') format('woff2'), url('/assets/fonts/Roboto/Roboto-regular/Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'robotomedium';
	src: url('/assets/fonts/Roboto/Roboto-medium/Roboto-Medium.woff2') format('woff2'), url('/assets/fonts/Roboto/Roboto-medium/Roboto-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'robotolight';
	src: url('/assets/fonts/Roboto/Roboto-light/Roboto-Light.woff2') format('woff2'), url('/assets/fonts/Roboto/Roboto-light/Roboto-Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'robotothin';
	src: url('/assets/fonts/Roboto/Roboto-thin/Roboto-Thin.woff2') format('woff2'), url('/assets/fonts/Roboto/Roboto-thin/Roboto-Thin.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'robotoblack';
	src: url('/assets/fonts/Roboto/Roboto-black/roboto-black.woff2') format('woff2'), url('/assets/fonts/Roboto/Roboto-black/Roboto-Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'latobold';
	src: url('/assets/fonts/Lato/Lato-bold/Lato-Bold.woff2') format('woff2'), url('/assets/fonts/Lato/Lato-bold/Lato-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'latoregular';
	src: url('/assets/fonts/Lato/Lato-regular/Lato-Regular.woff2') format('woff2'), url('/assets/fonts/Lato/Lato-regular/Lato-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
    font-family: 'Segoe UI';
    src:url('/assets/fonts/Segoe-UI/SegoeUI-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Segoe-UI/SegoeUI-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/assets/fonts/Segoe-UI/SegoeUI.woff2') format('woff2'),
         url('/assets/fonts/Segoe-UI/SegoeUI.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src:url('/assets/fonts/Segoe-UI/SegoeUI-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Segoe-UI/SegoeUI-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src:url('/assets/fonts/Segoe-UI/SegoeUI-Light.woff2') format('woff2'),
        url('/assets/fonts/Segoe-UI/SegoeUI-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src:url('/assets/fonts/Segoe-UI/SegoeUI-Bold.woff2') format('woff2'),
        url('/assets/fonts/Segoe-UI/SegoeUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src:url('/assets/fonts/Segoe-UI/SegoeUI-Italic.woff2') format('woff2'),
        url('/assets/fonts/Segoe-UI/SegoeUI-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

