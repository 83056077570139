.tour-modal {
     /* position: relative;
top: 70%;
    left: 70%;
    transform: translate(-50%, -50%);
    background-color: rgb(250, 248, 248);
    padding: 20px;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.5); */
    z-index: 10;
}



 .cd-more-info {
     display: flex;
     flex-direction: column;
     padding: 1rem;
     width: 370px;
     background: #F7F7F6;
     border-radius: 8px;
     gap: 16px;
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px;
     z-index: 1000 !important;
     overflow: hidden;
 }

 .step-no {
     display: flex;
     align-items: center;
     justify-content: space-between;
     color: black;
 }

 .step-no h4 {
     margin: 0;
     font-size: 20px;
 }

 .page-no {
     display: flex;
     justify-content: space-between;
     margin-top: 13px;
 }

 .page-no p {
     margin: 0;
 }

 .page-steps {
     display: flex;
     gap: 16px;
 }

 .page-btn {
     cursor: pointer;
 }
 .fa-close{
    color: #9a9999;
 }