@media only screen and (device-width: 2560px) {
  h4 {
    font-size: 1.5rem !important;
  }
}

// ---------------------------------------------- Favorities popups ---------------------------------------------
.fav-body {
  padding-top: 0px !important;
  p {
    font-size: 13px;
    text-align: justify;
    i {
      color: red;
    }
  }
}
.fav-ser {
  padding: 0px !important;
}
.code {
  height: 5rem;
  .reg_12 {
    top: -1.5rem;
    .input-group-prepend {
      width: 15% !important;
    }
  }
  .comp-name12 {
    width: 100% !important;
  }
  .comp-name1 {
    width: 85%;
  }
}


// .........................About...............
.about-wrapper{
  padding:30px 0;
}
.sub-about h3{
  font-size:25px;color:#2d0b4e;
}
.sub-about p{
  font-size: 17px;
  ;
}
.founders{
  padding-top: 40px;
}
.founders h2{
  text-align: center;font-size:32px;color:#2A2A2A;position: relative;
}
.founders h2:after{
  position: absolute;content:'';width:5%;height:3px;background-color: #2a2a2a;left:0;right:0;margin:auto;bottom:0;
}
.founder-card figure{
  position:relative;width:250px;height:250px;margin:auto;margin-bottom: 15px;
}
.founder-card figure img{
  position: absolute;max-width:100%;max-height:100%;border-radius: 100%;left:0;right:0;margin:auto;
}
.activity .pl-0{
  padding-left: 0;
}
.activity .pr-0{
  padding-right: 0;
}
.founder-card{
  margin-top:20px;text-align: center;
}
.founder-card p{
  font-size:14px;text-align: left;
}
.founder-card h4{
  color:#0F0F0F;font-size: 20px;font-weight: initial;margin-bottom: 0;
}
.founder-card h5{
  color:#0F0F0F;font-size: 16px;font-weight: initial;
}
.founder-card a img{
  max-width:11%;margin:15px 0;
}
.activity-right img{
  max-width: 100%;right:-15px;position: relative;
}
.activity-left{
  color:#fff;
}
.activity-left{
  padding:15px;
}
.activity-left p{
  font-size: 15px;
}
.activity-card{
  margin-top:20px;
}
.activity-left h5{
  color:#fff;font-weight:bold;
}
.activity-left h3{
  color:#fff;font-size: 18px;
}
.activity a{
  background-color: #fff;padding:10px 30px;margin-bottom: 20px;display: inline-block;margin-left: 30px;
}





//------------------------------------------Awards Popup---------------------------------------------------------
.award-text {
  .MuiTextField-root {
    @media only screen and (max-width: 600px) {
      width: inherit !important;
    }
  }
  .MuiOutlinedInput-input {
    height: 6rem;
    @media only screen and (max-width: 600px) {
      height: 1.1876rem !important;
    }
  }
}
.award-add {
  color: #5cbcb1;
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
}
.awd-date {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 15px;
}
.brandCard {
  @media only screen and (max-width: 600px) {
    margin-left: 0px !important;
  }
}
.res-padr {
  @media only screen and (max-width: 600px) {
    padding-right: 0px !important;
  }
}
// -------------------------------------------------- title design -----------------------------------------------
.pg-title {
  text-align: center;
  padding-top: 1.6876rem;
}
.pg-tit-img {
  text-align: center;
}
.tit-card {
  margin-bottom: 0px !important;
  .icon-tag {
    border-right: 2px solid #e9ecef;
  }
}
.card-wrapper {
  padding: 0px !important;

  .card-body {
    @media only screen and (device-width: 320px) {
      padding: 0 !important;
    }
  }
}
// ------------------------------------------------- Listing styles ---------------------------------------------------------
.list-tab {
  .list-main {
    width: 40%;
    height: 8rem;
    margin-right: 10px;
    @media only screen and (max-width: 600px) {
      width: 100% !important;
    }
    @media only screen and (device-width: 2560px) {
      height: 10rem;
    }

    .nav-link {
      width: 100%;
      height: 8rem;
      padding: 15px;
      background-color: #e9ecef;
      @media only screen and (max-width: 600px) {
        width: 100% !important;
      }
      @media only screen and (device-width: 2560px) {
        height: 10rem;
      }
    }
  }
  .list-ven {
    width: 17%;
    height: 8rem;
    margin-right: 10px;
    @media only screen and (max-width: 600px) {
      width: 100% !important;
    }
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
      // text-align: center;
    }
    .nav-link {
      width: 100%;
      height: 8rem;
      padding: 20px;
      background-color: #e9ecef;
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        width: 100% !important;
      }
    }
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background-color: #fff !important;
    border-bottom: none !important;
  }
  .icon-tag1 {
    flex: 0 0 15.66667% !important;
    max-width: 21.66667% !important;
  }
  .icon-tag2 {
    text-align: center;
    border-left: 2px solid #b8b8b8;
    @media only screen and (max-width: 600px) {
      padding-left: 0px !important;
    }
  }
  .sub-a {
    a {
      color: #000;
    }
  }
  .tab-content {
    background-color: #fff !important;
  }
}
.list-adtab {
  margin-right: 0.3rem;
  cursor: pointer;
  height: 6rem;
  padding-top: 2rem;
  width: initial;
  @media only screen and (max-width: 1051.96px) {
    width: 20% !important;
    // padding-top: 0px !important;
  }
  @media only screen and (max-width: 1200.96px) and (min-width:1051.96px) {
    width: 13% !important;
    // padding-top: 0px !important;
  }
  @media only screen and (max-width: 600px) {
    width: 100% !important;
    padding-top: 0px !important;
  }
  @media only screen and (max-width: 550px) {
    width: 100% !important;
    padding-top: 0px !important;
  }
  .nav-link {
    background-color: #e9ecef;
    padding-top: 0.6rem;
    height: 5rem;
    text-align: center;
    @media only screen and (max-width: 600px) {
      width: 100% !important;
      text-align: center !important;
    }
    h4 {
      @media only screen and (max-width: 1600px) and (min-width: 900px) {
        line-height: 3 !important;
        margin-bottom: 0.3rem !important;
        font-size: 0.8rem !important;
      }
    }
  }
}
.res-padr1 {
  @media only screen and (max-width: 1600px) {
    padding-right: 0px !important;
    padding: 0 !important;
  }
}

.pb-31 {
  padding-bottom: 1.3rem !important;
}
/////--------------------------------------channel detail----------------------------------------
.res-ph {
  @media screen and (max-width: 1440px) and (min-width: 1280px) {
    position: absolute !important;
    margin-left: 1.3rem !important;
  }
}
.complog {
  position: absolute;
  top: -4.5rem;
}
.curs-point {
  cursor: pointer;
}
.butn-f {
  border-radius: 50px;
  height: 2.5rem;
  background-color: #0a66c2;
  border-color: #0a66c2;
  color: #ffff;
  margin-left: 0 !important;
}
.butn-f:hover {
  border-radius: 50px;
  height: 2.5rem;
  background-color: #004182;
  border-color: #004182;
  color: #ffff;
}
.tet {
  position: absolute;
  right: 1rem;
  font-size: 23px;
  top: 1rem;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 0.2rem;
  text-align: center;
  border-radius: 50%;
  background-color: #d1d2cedb;
}
.cnect-brnd {
  position: absolute;
  right: 3rem;
  top: 6rem;
  width: 30%;
  text-align: right;
  @media only screen and (max-width: 600px) {
    position: absolute;
    right: 2rem;
    top: 9rem;
    width: 53%;
    text-align: right;
  }
  @media only screen and (max-width: 2560px) {
    right: 8rem;
    top: 9rem;
  }
  @media only screen and (max-width: 1024px) {
    right: 7rem;
  }
  @media only screen and (max-width: 1440px) {
    right: 7rem;
  }
  @media only screen and (max-width: 426px) {
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
    text-align: center !important;
  }
  button {
    @media only screen and (max-width: 1400px) {
      margin-bottom: 5px;
    }
  }
  .butn-f {
    @media screen and (max-width: 769px) {
      width: 100% !important;
    }
    @media screen and (max-width: 500px) {
      width: 80% !important;
    }
  }
}

.faplus1 {
  color: #5cbcb1;
  font-size: xx-large;
  cursor: pointer !important;
}

.product-card {
  padding: 25px;
  background: #fff;
  width: 100%;
  height: 28rem;
  position: relative;
  border-radius: 0.375rem;
  overflow: visible;
  margin: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: inline-block;
  border: 1px solid rgb(184, 184, 184);
  cursor: pointer;

  .set-image {
    img {
      width: 50%;
      height: 100%;
      background-color: #fff;
      object-fit: contain;
    }
    border-radius: 0.375rem;
    // border: 1px solid rgb(184, 184, 184);
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    font: bold 20px Sans-Serif;
    text-transform: uppercase;
    // display: table;
    .product-title {
      width: 46%;
      text-align: center;
      overflow: hidden;
      height: 74%;
      padding: 95px 10px 5px 5px;
      display: inline-block;
      position: absolute;
    }
  }

  .set-image p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    line-height: 25px;
    color: #d2d2d2;
  }
}

.ribbon-wrapper-8 {
  width: 115px;
  height: 115px;
  overflow: hidden;
  position: absolute;
  top: 13px;
  right: -11px;
}

.ribbon-8 {
  font: bold 15px Sans-Serif;
  line-height: 18px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -4px;
  top: 30px;
  width: 158px;
  background-color: #6daaab;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
  box-shadow: -3px 5px 6px -5px rgba(0, 0, 0, 0.5);
  outline: 1px dashed #fff;
  outline-offset: -4px;
}

.ribbon-8:before,
.ribbon-8:after {
  content: "";
  border-top: 4px solid #4e7c7d;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px;
}

.ribbon-8:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 4px solid #4e7c7d;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-top: 4px solid #4e7c7d;
}

.ribbon-8:after {
  content: "";
  position: absolute;
  right: 7px;
  top: 100%;
  z-index: -1;
  border-left: 4px solid transparent;
  border-right: 4px solid #4e7c7d;
  border-bottom: 4px solid transparent;
  border-top: 4px solid #4e7c7d;
}

.key-card {
  padding: 25px;
  background: #fff;
  // width: 100%;
  // height: auto;
  width: 280px;
  height: 325px;
  position: relative;
  border-radius: 0.375rem;
  overflow: visible;
  margin: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: inline-block;
  border: 1px solid rgb(184, 184, 184);
  @media only screen and (max-width: 600px) {
    margin-left: 0px !important;
  }
  .set-headline {
    width: 100%;
    height: 10%;
    // background: #eee;
    // margin: 20px; }
    h3 {
      white-space: nowrap;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .set-image {
    img {
      width: 100%;
      height: 100%;
      background-color: #fff;
      object-fit: contain;
    }
    border-radius: 0.375rem;
    // border: 1px solid rgb(184, 184, 184);
    width: 100%;
    height: 80%;
    background: #eee;
    overflow: hidden;
    font: bold 20px Sans-Serif;
    text-transform: uppercase;
    // display: table;
    .product-title {
      width: 46%;
      text-align: center;
      overflow: hidden;
      height: 80%;
      padding: 28px;
      display: inline-block;
      position: absolute;
    }
  }

  .set-image p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    line-height: 25px;
    color: #d2d2d2;
  }
}
.ribbon-wrapper-3 {
  width: 106px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  left: -6px;
}

.ribbon-3 {
  font: bold 15px Sans-Serif;
  line-height: 18px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -33px;
  top: 26px;
  width: 150px;
  background-color: #2c9db7;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.ribbon-3:before,
.ribbon-3:after {
  content: "";
  border-top: 4px solid #4e7c7d;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px;
}

.ribbon-3:before {
  left: 0;
}

.ribbon-3:after {
  right: 0;
}

.plan-badge {
  position: absolute;
  width: 15%;
  top: 2rem;
  right: 0rem;
  height: 3rem;
  @media only screen and (max-width: 600px) {
    width: 50% !important;
    right: 1rem !important;
  }
}
.meeting-dialog{
  min-width:700px !important;
}

.ribbon-wrapper-3 {
  width: 106px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  left: -6px;
}

.ribbon-3 {
  font: bold 15px Sans-Serif;
  line-height: 18px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -33px;
  top: 26px;
  width: 150px;
  background-color: #2c9db7;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.ribbon-3:before,
.ribbon-3:after {
  content: "";
  border-top: 4px solid #4e7c7d;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px;
}

.ribbon-3:before {
  left: 0;
}

.ribbon-3:after {
  right: 0;
}

.plan-badge {
  position: absolute;
  width: 15%;
  top: 5rem;
  right: 4rem;
  height: 3rem;
  @media only screen and (max-width: 600px) {
    width: 50% !important;
    right: 1rem !important;
  }
  @media only screen and (max-width: 900px) and (min-width: 600px) {
    width: 39% !important;
  }
  @media only screen and (device-width: 1440px) {
    width: 22% !important;
  }
  @media only screen and (device-width: 1400px) {
    width: 23% !important;
  }
  @media only screen and (device-width: 1366px) {
    width: 22% !important;
  }
  @media only screen and (device-width: 1360px) {
    width: 22% !important;
  }
  @media only screen and (device-width: 1280px) {
    width: 24% !important;
  }
  @media only screen and (max-width: 1680px) and (min-width: 1441px) {
    width: 19% !important;
  }
  @media only screen and (max-width: 1024px) {
    width: 30% !important;
  }
  @media only screen and (max-width: 768px) {
    width: 40% !important;
  }
  @media only screen and (max-width: 426px) {
    width: 60% !important;
  }
  @media only screen and (max-width: 320px) {
    width: 77% !important;
  }
  @media only screen and (max-width: 376px) {
    width: 75% !important;
  }

  .c-1 {
    height: 3rem;
  }
  .plan-txt {
    height: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding-top: 0.6rem;
    padding-right: 1.9rem;
    padding-bottom: 0.6rem;
    padding-left: 0.6rem;
    @media only screen and (max-width: 600px) {
      text-align: left !important;
      padding-top: 0.3rem;
    }
    h3 {
      color: #fff !important;
      @media only screen and (max-width: 600px) {
        font-size: 0.8rem !important;
      }

      @media only screen and (max-width: 2560px) and (min-width: 601px) {
        font-size: 1rem !important;
      }
      @media only screen and (device-width: 1920px) {
        font-size: 0.9rem !important;
      }
    }
    @media only screen and (max-width: 426px) {
      padding-top: 15px;
    }
  }
  .plan-txt1 {
    height: 100%;
    border-radius: 50%;
    width: 3rem;
    margin-left: -26px;
    background-color: #fff;
    padding: 0.7rem 0.9rem;
    i {
      font-size: 1.5rem;
      margin-left: 0.1rem;
      color: red;
    }
    .str-i {
      position: absolute !important;
      font-size: 0.7rem !important;
      left: -9px !important;
      top: 0.9rem !important;
      color: #fff !important;
    }
  }
}

#channel-detail {
  .rightdivider {
    border-right: 2px solid #e9ecef;
  }
  .double-badge {
    cursor: pointer;
    span:nth-child(1) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      background-color: #ff6347;
      color: white;
      font-size: 0.9rem;
    }
    span:nth-child(2) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-left: 0px !important;
      background-color: white;
      color: black;
      font-size: 0.9rem;
    }
  }
  .counts {
    .double-badge {
      // display: inline-flex;
      span {
        width: 80%;
        border-radius: 0.375rem;
      }
      span:nth-child(1) {
        background-color: rgb(44, 157, 183);
        display: inline-block;
        color: white;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      span:nth-child(2) {
        background-color: #eaecfb;
        color: black;
        display: inline-block;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
  .MuiRating-root {
    color: black !important;
  }
  #Countries {
    .selling,
    .target {
      border: 2px dashed #aaa;

      border-radius: 10px;
    }
  }
}
.border_bottom {
  border-bottom: 1px solid #b8b8b8;
}
#leftFilter {
  background-color: #f8f9fe;
  li {
    padding: 0.5rem 0.5rem !important;
    // cursor: pointer;
    border: none;
  }
}
.right-search {
  position: absolute;
  top: 4.2rem;
  right: 12rem;
  width: 23%;
  @media only screen and (max-width: 1440px) {
    left: 63% !important;
  }
  @media only screen and (max-width: 1280px) {
    left: 60% !important;
  }
  @media only screen and (device-width: 1024px) {
    left: 55% !important;
  }
  @media only screen and (device-width: 768px) {
    left: 43% !important;
  }
  @media only screen and (max-width: 500px) {
    width: 13rem !important;
  }
  @media only screen and (max-width: 380px) {
    width: 10rem !important;
  }
  @media only screen and (max-width: 330px) {
    width: 12rem !important;
  }
}
.right-sort {
  position: absolute;
  top: 4.9rem;
  right: 5rem;
  // @media only screen and (max-width: 1440px) {
  //   left: 93% !important;
  // }
  @media only screen and (max-width: 1440px) {
    left: 87% !important;
  }

  @media only screen and (device-width: 320px) {
    left: 70% !important;
  }
  @media only screen and (max-width: 412px) and (min-width: 330px) {
    left: 75% !important;
  }
  @media only screen and (device-width: 425px) {
    left: 78% !important;
  }

  .dropdown-menu {
    min-width: 100% !important;
  }
}
.account_status_btn {
  @media only screen and (device-width: 320px) {
    text-align: center !important;
  }
  .btn {
    @media only screen and (device-width: 320px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
.input-group-text {
  background-color: #f7fafe;
  border: none;
}
.avatar img {
  object-fit: contain;
}

#channel-detail {
  .badges {
    span:nth-child(1) {
      background-color: #ff6347;
      color: white;
      font-size: 0.9rem;
    }
  }
  .outer_badge {
    background-color: white;
    color: rgb(255, 99, 71);
    border: 1px solid rgb(255, 99, 71);
  }
  .outer_badge1 {
    background-color: white;
    color: #2c9db7;
    border: 1px solid #2c9db7;
    font-size: 0.9rem;
  }
  .btn-icon-only {
    width: 1.375rem;
    height: 1.375rem;
  }
}
.conne-card {
  flex: 0 0 20% !important;
  max-width: 20% !important;
  @media only screen and (max-width: 600px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.conne {
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }
}
.B-prod {
  max-width: 12rem;
  height: 3rem;
  padding-top: 17px;
  font-weight: 700;
  font-size: 0.9rem !important;
  background-color: transparent;
  color: #32325d;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #b8b8b8;
  width: 231% !important;
}
.detailbadge {
  max-width: 12rem;
  border: 1px solid #b8b8b8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  background-color: transparent;
}
.B-prod1 {
  width: 100%;
  height: 3rem;
  padding-top: 17px;
  font-weight: 700;
  font-size: 0.9rem !important;
  background-color: #2c9db7;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.detv-contact {
  border: 1px solid #b8b8b8;
  border-radius: 10px;
  padding: 25px 15px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.15) !important;
  // height: 20rem;
  overflow: hidden;
  @media only screen and (device-width: 1024px) {
    padding: 5px 15px !important;
  }
  @media only screen and (max-width: 375.96px) {
    height: auto !important;
  }
  .row {
    @media only screen and (max-width: 500px) {
      justify-content: center;
      margin: none !important;
    }
  }
  .conne-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    @media only screen and (max-width: 1788px) and (min-width: 900px) {
      width: 70px !important;
      height: 70px !important;
    }
  }
  .conne-comp {
    width: 100px;
    height: 100px;
    border-radius: 10%;
    overflow: hidden;
    margin: 0px 10px 0px 24px;
    @media only screen and (max-width: 1788px) and (min-width: 900px) {
      width: 70px !important;
      height: 70px !important;
    }
  }
  button {
    border-radius: 15px !important;
  }
}
.conn-btn {
  .btn-icon-only {
    width: 2rem !important;
    height: 2rem !important;
    @media only screen and (max-width: 1788px) and (min-width: 900px) {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
  .btn-slack1 {
    color: #fff;
    background-color: #5cbcb1;
    border-color: #5cbcb1;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
}
.conn-cont {
  height: 8rem;
}
.ribbon-9 {
  top: -15rem !important;
  right: -40px !important;
  width: 125px !important;
  @media only screen and (max-width: 600px) {
    top: -17rem !important;
    right: -52px !important;
    width: 160px !important;
  }
  @media only screen and (device-width: 2560px) {
    width: 170px !important;
  }
  @media only screen and (device-width: 1440px) {
    width: 125px !important;
  }
}
.minheit {
  min-height: 11rem;
}
// ------------------------------------------------- Listing Page ---------------------------------------------------------
#listing {
  .list-tab .tab-content {
    background-color: rgb(248, 249, 254) !important;
  }
}
.heart-o,
.heart {
  position: absolute;
  top: -72px;
  right: 20px;
  font-size: 2rem;
  @media only screen and (min-width: 1900px) {
    position: absolute !important;
    top: -78px !important;
    right: 20px !important;
    font-size: 2rem !important;
  }
  @media only screen and (max-width: 600px) {
    position: initial !important;
  }
  @media screen and (max-width: 1124px) and (min-width: 602px) {
    position: relative !important;
    top: 105px !important;
    right: 15px !important;
    font-size: 2rem !important;
  }
}
.heart {
  color: #dcdcdc;
}
.heart:active {
  color: #e64c3c;
}
.fav-ser1 {
  background-color: white;
  padding-bottom: 1.5rem !important;
  .MuiOutlinedInput-root {
    border-radius: 0px !important;
  }
  .form-group ul {
    max-height: 17rem !important;
  }
  .MuiAutocomplete-paper {
    box-shadow: none !important;
    height: auto !important;
  }
  .MuiAccordionSummary-root {
    padding: 10px 16px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
  }
  .list-group {
    position: relative !important;
    top: -2rem !important;
  }
  .list-group-item {
    padding: 0px !important;
    border: none !important;
    white-space: nowrap !important;
  }
  .MuiCheckbox-root {
    margin-right: 0px !important;
    padding: 3px !important;
  }
  .MuiTypography-body1 {
    width: 100% !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  ul:hover {
    ::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 5px;
    }
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .MuiAutocomplete-popper {
    z-index: 1;
    width: 100% !important;
  }
}
.menapic-wrap{
  padding: 15px;box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);border-radius: 2px;float: left;
}
.menapic-img{
  position: relative;width: 120px;height: 120px;float: left;
}
.menapic-img img{
  position: absolute;left: 0;max-width: 100%;max-height: 100%;top: 0;
}
.btn-photoview{
  background-color: #008cdd;color: #fff;padding: 10px 20px;border-radius: 3px;display: table;margin: auto;margin-top: 20px;
}


// ---------------------------------------------------- Drawer ------------------------------------------------------------
.site-form-in-drawer-wrapper {
  padding: 15px 16px;
  text-align: center;
}
.ant-drawer-content-wrapper {
  width: 50% !important;
  @media screen and (max-width: 1124px) and (min-width: 602px) {
    width: 720px !important;
  }
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  -webkit-transition: height 0s ease 0.3s, width 0s ease 0.3s,
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: height 0s ease 0.3s, width 0s ease 0.3s,
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s,
    width 0s ease 0.3s;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s,
    width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer > * {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
    9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
    9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
    -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
    -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
    0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
    0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-drawer-wrapper-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 10px 10px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  -webkit-transition: opacity 0.3s linear, height 0s ease 0.3s;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer-open-content {
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}
//   ---------------------------------------------------Profile setting ----------------------------------------------------
.slider-wrapper {
  width: 760px;
  margin: auto;
}
.slick-slide {
  text-align: center;
  position: relative;
}
 
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: capitalize;
}
.prof-slide {
  div {
    height: 7rem !important;
  }
}
.slick-prev:before,
.slick-next:before {
  color: #2c9db7 !important;
}
.ban-preview {
  border: 1px solid #d0d0d0;
  // height: 350px;
  width: 100%;
  // border-radius: 5px;
  padding: 10px;
  .imgContiner1 {
    // width: 100%;
    // height: 100%;
    position:relative; width:100%;height:400px;
  }
  img {
    // width: 100% !important;
    // object-fit: fill !important;
    position: absolute;max-width:100%;max-height: 100%;top:0;left:0;right:0;bottom:0;margin:auto;
  }
}
////-----------------Product List---------------
.down-dismiss {
  position: relative;
  top: 4%;
  left: 82%;
  font-size: 1.2rem;
  color: #525f7f;
  cursor: pointer;
  @media only screen and (device-width: 1920px) {
    left: 87%;
  }
  @media only screen and (device-width: 2560px) {
    left: 77%;
    font-size: 1.5rem;
  }
}
.pre-vid {
  width: 100%;
  height: 20rem;
}
.MuiSlider-root {
  color: #2c9db7 !important;
}
#Product {
  .outline,
  .fill {
    position: absolute !important;
    right: 63px !important;
    top: 10px !important;
    cursor: pointer !important;
    font-size: 1.2rem !important;
    z-index: 2 !important;
    @media only screen and (device-width: 2560px) {
      font-size: 1.5rem !important;
    }
  }
  .fill {
    color: #e64c3c;
  }
  .outline {
    color: rgb(168, 168, 171);
  }
  .outline:hover {
    color: #e64c3c;
  }
  .fill:hover {
    color: rgb(168, 168, 171);
  }
  .productIcon {
    i {
      font-size: 20px;
      position: absolute;
      opacity: 0.7;
    }

    .Icon1,
    .Icon2,
    .Icon3,
    .Icon4 {
      cursor: pointer;
      position: relative;
      width: 40px;
      padding: 9px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid rgb(238, 238, 238);
    }
    .Icon1:hover,
    .Icon2:hover,
    .Icon3:hover,
    .Icon4:hover {
      i {
        opacity: 1;
      }
    }
    .Icon2 {
      padding: 8px;
    }
    .Icon3 {
      padding: 10px;
    }
  }

  .awards {
    div {
      width: 55px;
      height: 55px;
      border: 1px solid #eee;
      margin-left: 1rem;
      border-radius: 0.375rem;
      img {
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: contain;
        border-radius: 0.375rem;
      }
    }
  }

  .products-card {
    background: #fff;
    width: 100%;
    height: 28rem;
    position: relative;
    border-radius: 0.375rem;
    overflow: visible;
    margin: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: inline-block;
    @media only screen and (max-width: 600px) {
      margin-left: 0px !important;
      height: 30rem !important;
    }
    .set-headline {
      width: 100%;
      height: 10%;
      // background: #eee;
      // margin: 20px; }
      h3 {
        border-bottom: 1px solid #eee;
        white-space: nowrap;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 7px;
      }
    }
    .set-headline1 {
      width: 100%;
      height: 10%;
      // background: #eee;
      // margin: 20px; }
      h3 {
        border-bottom: none;
        white-space: nowrap;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 35px;
      }
    }

    .brandImg {
      border-radius: 0.375rem;
      width: 65px;
      max-height: 65px;
      background: #fff;
      // position: absolute;
      // left: 10px;
      // top: 10px;
      margin: 1rem !important;
      border: solid 1px #eeeeee;
      img {
        border-radius: 0.375rem;
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: contain;
      }
    }

    .set-image {
      img {
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: contain;
      }
      border-radius: 0.375rem;

      width: 100%;
      height: 325px;
      max-height: 325px;
      min-height: 325px;
      background: #fff;
      overflow: hidden;
      font: bold 20px Sans-Serif;
      text-transform: uppercase;
      // display: table;
      .product-title {
        width: 46%;
        text-align: center;
        overflow: hidden;
        height: 80%;
        padding: 28px;
        display: inline-block;
        position: absolute;
      }
    }

    .set-image p {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      line-height: 25px;
      color: #d2d2d2;
    }
  }
  .ribbon-wrapper-3 {
    width: 106px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    left: -6px;
  }

  .ribbon-3 {
    font: bold 15px Sans-Serif;
    line-height: 18px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    left: -33px;
    top: 26px;
    width: 150px;
    background-color: #2c9db7;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.5px;
  }

  .ribbon-3:before,
  .ribbon-3:after {
    content: "";
    border-top: 4px solid #4e7c7d;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
  }

  .ribbon-3:before {
    left: 0;
  }

  .ribbon-3:after {
    right: 0;
  }
}
.parprod-des{
  font-size: 12px;
}
.vend-size{
  font-size: 14px;
}
.hund-height{
  height:100%;
}
.col-height{
  height: 440px;
}
.div-mar{
  margin-bottom: 20px;
}
// -------------------------------------Fav Popup---------------------------------------
.fav-list {
  padding-left: 1rem !important;
  padding-top: 0.8rem !important;
  border-radius: 5px !important;
}
.fav-count {
  width: 35px;
  height: 28px;
  background: #fff;
  color: #616365;
  border-radius: 10%;
  padding: 4px 0 0;
  font-size: 14px;
  float: right;
  position: relative;
  right: -6.75rem;
  text-align: center;
  top: -55px;
}
.fav-cl {
  position: absolute;
  background: #f8f8f8;
  color: #2c9db7;
  top: -10px;
  right: -103px;
  border: 1px solid #2c9db7;
  border-radius: 50%;
  padding: 1px 6px;
  z-index: 3;
  text-align: center;
  cursor: pointer;
}

.ribbon-9::before {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 25px solid #fff;
  border-bottom: 17px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.ribbon-9 {
  top: -13rem;
  width: 142px;
  padding: 5px;
  position: relative;
  right: -15px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  float: right;
  margin-bottom: 10px;
  background: #5cbcb1;
}

.ribbon-10 {
  margin-top: 6%;
  padding: 5px;
  color: #fff;
  font-size: 14px;
  display:flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.ratingText{
    max-width: 100%; 
    overflow: hidden;
    display: inline-block; 
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ribbon-10:hover .titletag {
  display: block;
}

.titletag{
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  transform: translate(0%, 40%);
}

///-----------------------------product-detail-----------------------------
.product {
  .right-arrow {
    position: absolute;
    right: 4rem;
    top: 5.4rem;
    font-size: 35px;
    color: #adb5bd;
    z-index: 100;
    cursor: pointer;
    transition: ease 0.3s;
    @media only screen and (max-width: 600px) {
      right: 1rem !important;
    }
  }
  .left-arrow {
    position: absolute;
    left: 4rem;
    top: 5.4rem;
    font-size: 35px;
    color: #adb5bd;
    cursor: pointer;
    z-index: 100;
    cursor: pointer;
    transition: ease 0.3s;
    @media only screen and (max-width: 600px) {
      left: 1rem !important;
    }
  }
  .right-arrow:hover,
  .left-arrow:hover {
    font-size: 38px;
    top: 5.4rem;
    color: #5cbcb1;
    cursor: pointer;
    z-index: 100;
    cursor: pointer;
  }
}
.w-30 {
  width: 30%;
}
.flag {
  width: 20px;
  height: 20px;
  background-color: white;
}
#product-detail {
  .slick-prev {
    top: -2rem;
    opacity: 1 !important;
    left: 40%;
    transform: rotate(90deg);
    position: absolute;
    z-index: 2;
    @media only screen and (device-width: 1440px) {
      top: 8.5rem !important;
    }
  }
  .slick-next {
    top: 102%;
    left: 40%;
    opacity: 1 !important;
    transform: rotate(90deg);
  }
  //  .slick-list{
  //  height: 53vh !important;
  //  }
  .magnifier {
    height: 60vh !important;
  }
  .awd-gri {
    flex: 0 0 10.66667% !important;
    max-width: 25.66667% !important;
    @media only screen and (max-width: 1600px) and (min-width: 900px) {
      flex: 0 0 12.66667% !important;
      max-width: 39.66667% !important;
    }
    @media only screen and (max-width: 900px) and (min-width: 600px) {
      flex: 0 0 auto !important;
      max-width: 33% !important;
    }
    @media only screen and (max-width: 600px) {
      flex: 0 0 auto !important;
      max-width: 90% !important;
    }
  }
  .key-cardawd {
    padding: 5px;
    background: #fff;
    // width: 100%;
    // height: auto;
    width: 160px;
    height: 200px;
    position: relative;
    border-radius: 0.375rem;
    overflow: visible;
    margin: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: inline-block;
    border: 1px solid rgb(184, 184, 184);

    .set-headline {
      width: 100%;
      height: 22%;
      overflow: hidden;
      white-space: nowrap;
      // background: #eee;
      // margin: 20px; }
      h4 {
        width: 98%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    // .set-headline:hover{
    //   white-space: normal !important;
    //   height: 22% !important;
    // }

    .set-image {
      img {
        width: 100%;
        height: 100%;
        background-color: #fff;
        object-fit: contain;
      }
      border-radius: 0.375rem;
      // border: 1px solid rgb(184, 184, 184);
      width: 100%;
      height: 100%;
      background: #eee;
      overflow: hidden;
      font: bold 20px Sans-Serif;
      text-transform: uppercase;
      //display: table;
      .product-title {
        width: 46%;
        text-align: center;
        overflow: hidden;
        height: 80%;
        padding: 28px;
        display: inline-block;
        position: absolute;
      }
    }

    .set-image p {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      line-height: 25px;
      color: #d2d2d2;
    }
  }

  .key-card {
    padding: 5px;
    background: #fff;
    // width: 100%;
    // height: auto;
    width: 190px;
    height: 240px;
    position: relative;
    border-radius: 0.375rem;
    overflow: visible;
    margin: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: inline-block;
    border: 1px solid rgb(184, 184, 184);

    .set-headline {
      width: 100%;
      height: 22%;
      overflow: hidden;
      white-space: nowrap;
      // background: #eee;
      // margin: 20px; }
      h4 {
        width: 98%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    // .set-headline:hover{
    //   white-space: normal !important;
    //   height: 22% !important;
    // }

    .set-image {
      img {
        width: 100%;
        height: 100%;
        background-color: #fff;
        object-fit: contain;
      }
      border-radius: 0.375rem;
      // border: 1px solid rgb(184, 184, 184);
      width: 100%;
      height: 100%;
      background: #eee;
      overflow: hidden;
      font: bold 20px Sans-Serif;
      text-transform: uppercase;
      //display: table;
      .product-title {
        width: 46%;
        text-align: center;
        overflow: hidden;
        height: 80%;
        padding: 28px;
        display: inline-block;
        position: absolute;
      }
    }

    .set-image p {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      line-height: 25px;
      color: #d2d2d2;
    }
  }
  .ribbon-wrapper-3 {
    width: 106px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    left: -6px;
  }

  .ribbon-3 {
    font: bold 12px Sans-Serif;
    line-height: 5px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);

    position: relative;
    padding: 7px 0;
    left: -33px;
    top: 26px;
    width: 150px;
    background-color: #2c9db7;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.5px;
  }

  .ribbon-3:before,
  .ribbon-3:after {
    content: "";
    border-top: 4px solid #4e7c7d;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
  }

  .ribbon-3:before {
    left: 0;
  }

  .ribbon-3:after {
    right: 0;
  }

  p {
    font-size: 14px;
  }
  .prodcompanytitle {
    color: #a8a8ab !important;
    @media only screen and (device-width: 2560px) {
      font-size: 1.6rem !important;
    }
  }
  .price {
    font-size: 30px !important;
  }
  .product-imgs {
    border-radius: 0.375rem;
    //width: 100%;
    width: 86px !important;
    min-height: 91px;
    max-height: 135px;
    background: #fff;
    padding: 5px;
    // border: solid 1px #eeeeee;
    @media only screen and (max-width: 1600px) and (min-width: 900px) {
      width: 88px !important;
      min-height: 88px;
      max-height: 88px;
      img {
        max-height: 88px;
      }
    }
    cursor: pointer;
    img {
      border-radius: 0.375rem;
      width: 100%;
      height: 76px;
      background: #fff;
      object-fit: cover;
    }
  }
  .js-image-zoom__zoomed-image {
    z-index: 99 !important;
    width: 67vh !important;
    //height: 65vh !important;
    top: 0px !important;
    //left: 45px !important;
    left: 156px !important;
    height: 78vh !important;
    background-color: white !important;
    border: 1px solid #5e72e4 !important;
  }
  .prod-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    // img{
    //     width: 100% !important;
    //   height: 80vh !important;

    // }
  }
  .center-img {
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    //   width: 60vh;
    //  height: 60vh;
    width: 100%;
    height: 100%;
    background: #fff;
    // width: 100%;
    position: relative;
    cursor: pointer;
    // div:first-child{
    //   width: 60vh !important;
    //   height: 60vh !important;
    // }
    img {
      border-radius: 0.375rem;
      width: 100% !important;
      height: 100% !important;
      background: #fff;
      object-fit: contain;
    }
    .img-zoom-lens {
      position: absolute;
      border: 1px solid #d4d4d4;
      /*set the size of the lens:*/
      width: 100px;
      height: 100px;
    }
    .img-zoom-container:hover {
      .img-zoom-result {
        //display: block;
        visibility: visible;
      }
    }
    .img-zoom-result {
      border: 1px solid #d4d4d4;
      /*set the size of the result div:*/
      position: absolute;
      top: 0px;
      left: 103%;
      width: 85%;
      height: 100%;
      z-index: 99;
      visibility: hidden;
    }
    .img-title {
      position: absolute;
      right: 15px;
      bottom: 10px;
    }
  }

  .productIcon {
    i {
      font-size: 20px;
      position: absolute;
      opacity: 0.7;
    }

    .Icon1,
    .Icon2,
    .Icon3,
    .Icon4 {
      cursor: pointer;
      position: relative;
      width: 40px;
      padding: 9px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid rgb(238, 238, 238);
    }
    .Icon1:hover,
    .Icon2:hover,
    .Icon3:hover,
    .Icon4:hover {
      i {
        opacity: 1;
      }
    }
    .Icon2 {
      padding: 8px;
    }
    .Icon3 {
      padding: 10px;
    }
    .heart-r {
      color: #e64c3c;
    }
    .heart-r:hover {
      color: #dcdcdc;
    }
  }
  .brandImg {
    border-radius: 0.375rem;
    width: 150px;
    height: 150px;
    background: #fff;
    text-align: center;
    border: solid 1px #eeeeee;
    img {
      border-radius: 0.375rem;
      width: 100%;
      height: 100%;
      background: #fff;
      object-fit: contain;
    }
  }
  .pointy {
    position: relative;
    background-color: orange;
    margin: 0 20px;
    display: inline-block;
    font-family: arial;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }
  .pointy:after {
    position: absolute;
    content: "";
    height: 0;
    width: 0;
    font-size: 0;
    line-height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid orange;
  }
  .pointy:before {
    position: absolute;
    content: "";
    left: -20px;
    height: 0;
    width: 0;
    font-size: 0;
    line-height: 0;
    border-top: 10px solid orange;
    border-bottom: 10px solid orange;
    border-right: 10px solid orange;
    border-left: 10px solid transparent;
  }
  .items {
    background-color: rgb(139, 150, 162);
    color: white;
    width: 120px;
    white-space: nowrap;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .qt {
    width: 60px;
    height: 35px;
    color: black;
  }
  .key {
    border-radius: 0.375rem;
    width: 70px;
    height: 70px;
    background: #fff;
    text-align: center;
    border: solid 1px #eeeeee;

    img {
      border-radius: 0.375rem;
      width: 100%;
      height: 100%;
      background: #fff;
      object-fit: contain;
    }
  }
  .more {
    position: absolute;
    right: 10px;
    bottom: -40px;
    cursor: pointer;
  }
  .active-btn {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4;
  }
  .prod-large-img {
    z-index: 9999;
  }
}

.eventtop {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #dcf1f1;
  overflow: hidden;
  padding: 100px 20px;
  box-sizing: border-box;
}

.evndt {
  border: 2px dashed rgb(107, 91, 91);
  padding: 1rem;
}
.evnnum {
  border: 4px solid rgb(161, 130, 49);
  width: 22%;
  margin-left: 23rem;
}
.numcard {
  background-color: rgba(255, 255, 255, 3.4);
  z-index: 4;
}

.outer_badge {
  background-color: white;
  color: rgb(255, 99, 71);
  border: 1px solid rgb(255, 99, 71);
}
.active-img {
  border: 1px solid #2c9db7;
}
.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #2c9db7 !important;
  color: #fff;
}
.Cardborder {
  // border: 1px solid #e6ecf1;
  border-radius: 0.25rem;
}

@media only screen and (min-width: 900px) {
  .evnnum {
    margin-left: 37rem;
  }
}

@media screen and (max-width: 1124px) and (min-width: 602px) {
  .evnnum {
    margin-left: 23rem;
  }
}
@media screen and (max-width: 1950px) and (min-width: 1440px) {
  .evnnum {
    margin-left: 25rem;
  }
}
//------------------------------------Notification----------------------------------
#Notification {
  .badge-blue {
    background-color: #2c9db7;
    color: white;
    border: 1px solid white;
  }
  .noty {
    i {
      position: absolute;
    }
    position: relative;
    width: 40px;
    height: 40px;
    padding: 9px;
    font-size: 1.2rem;
    border: 2px solid #eee;
    border-radius: 50%;
  }
  .notific {
    height: 65vh;
  }
  .unreed {
    color: #525f7f;
    background-color: #f6f9fc;
  }
  .unreed:hover {
    color: #525f7f;
    background-color: #fff;
  }
}
//-----------------------------------------Favourites------------------------------
#Favourites {
  .active {
    background-color: #fff !important;
    border-bottom: none !important;
  }
  .count {
    position: relative;
    left: 25%;
  }
}
#MyProduct {
  .active {
    background-color: #fff !important;
    border-bottom: none !important;
  }
  .count {
    position: relative;
    // left: 25%;
  }
}

///-------------Search Modal----------------
.searchHeight {
  height: 550px;
  transition: height 0.5s ease-in;
}
// ---------------------------------------Profile Settings --------------------------
.comp-name {
  width: 100%;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    // .MuiInputLabel-outlined{
    //   transform: translate(5px, 20px) scale(1) !important;
    // }
  }
}
.addrs {
  .MuiOutlinedInput-root {
    height: 217px;
  }
}
.addrs.billadress {
  .MuiOutlinedInput-root {
    height: 120px;
  }
}
.reg12 {
  width: 24.8rem !important;
}
.reg21 {
  width: 24.8rem !important;
}
.ftl-img {
  width: 75px;
  height: 75px;
  border: 1px solid #c6c6c6;
  padding: 5px;
  img {
    width: 100%;
    height: 100%;
  }
}
.active-bt {
  color: #fff;
  background-color: #2c9db7 !important;
  border-color: #2c9db7 !important;
}
.stripe {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#7dc5ee),
    color-stop(85%, #008cdd),
    to(#30a2e4)
  );
}
.set-prof {
  width: 98px;
  height: 98px;
  border: 1px solid #c4c4c4;
  padding: 5px;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    width: 80px !important;
    height: 80px !important;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.settings {
  .nav-item {
    cursor: pointer !important;
  }
}
.ftl-img1 {
  width: 65px;
  height: 65px;
  border-radius: 20px;
  padding: 5px;
  img {
    width: 100%;
    height: 100%;
  }
}
.user-card {
  height: 6rem;
  @media only screen and (max-width: 900px) {
    height: auto !important;
    .res-i {
      width: 100% !important;
      text-align: center !important;
    }
  }
}
.fa-pencil-alt {
  cursor: pointer;
}
.tip {
  position: relative;
  top: -11px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #46b88f;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    top: -10px !important;
    height: 9px !important;
    width: 9px !important;
  }
}
.pt4 {
  padding-top: 3.5rem;
}

///-----------------------------events------------------------------------
.monaco {
  height: auto;
  object-fit: cover;
}
.event_ban {
  height: 350px;
  object-fit: cover;
  @media only screen and (max-width: 600px) and (min-width: 300px) {
    height: 81px !important;
  }
  @media only screen and (max-width: 900px) and (min-width: 600px) {
    height: 150px !important;
  }
  @media only screen and (max-width: 1100px) and (min-width: 900px) {
    height: auto !important;
  }
  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    height: 260px !important;
  }
}
#Events {
  .event-card {
    background-color: #f8f9fe;
  }
  .event-logo {
    width: 130px;
    height: 130px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    @media only screen and (max-width: 1600px) and (min-width: 900px) {
      width: 95px !important;
      height: 95px !important;
    }
  }
  .event-title {
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 25px;
    line-height: 27px;
    text-decoration: none;
  }
  .event-Venue {
    font-weight: 600;
  }
  .event-venue-detail {
    font-size: 14px;
    font-weight: 600;
  }
}
// ...................................MouseOverpopup-----------------------------------
.MuiPopover-paper {
  background-color: #e9ecef !important;
}

///-----------------------------iner------------------------------------

#iner {
  a {
    color: #808080;
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    color: #525f7f;
    text-decoration: none;
  }
  .progress-bar {
    background-color: #2c9db7;
  }
}
// --------------------------------------Myproductlist------------------------------------
.prodlsact {
  text-align: -webkit-right;
  padding-bottom: 2rem;
  position: relative;
  top: 3.5rem;
  right: -36rem;
  width: 20rem;
  display: inline;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    right: -2rem !important;
  }
}
.right-search1 {
  position: relative;
  top: 3.2rem;
  right: -36rem;
  width: 20rem;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    right: -2rem !important;
  }
}
.right-sort1 {
  position: relative;
  top: 3.5rem;
  right: -38rem;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    right: -11rem !important;
  }
}
.res-md {
  h3 {
    width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    padding-left: 2.5rem !important;
  }
}
.res-spa {
  width: 75%;
  height: 2rem;
  padding: 0.6rem;
  border: 1px solid #46b88f;
  color: #46b88f;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    width: 100% !important;
    padding: 0.6rem 0rem 0.6rem 0.5rem !important;
  }
}
.res-spa1 {
  width: 75%;
  height: 2rem;
  padding: 0.6rem;
  border: 1px solid #46b88f;
  color: #46b88f;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    width: 125% !important;
    padding: 0.6rem 0rem 0.6rem 0.5rem !important;
  }
}
.ftl-img12 {
  width: 108px;
  height: 108px;
  border-radius: 20px;
  padding: 5px;
  img {
    width: 100%;
    height: 100%;
  }
}
/////-----------------------------------PaymentConfirm----------------------------
#PaymentConfirm {
  .amt {
    color: #525f7f !important;
  }
  .rowBox {
    padding: 1.25rem;
    border: 1px solid #e6ecf1;
    border-radius: 0.25rem;
    margin-bottom: 2rem;
    img {
      width: 100px;
      height: 50px;
      object-fit: contain;
    }
  }
  .leftCol-top {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .leftCol-bottom {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .left {
    background: #f8f9f4;
  }
  .scrolhide {
    white-space: normal !important;
  }
  .btn-apply {
    padding: 0.5rem 1.25rem;
    margin-left: -2px;
  }
  .paycarhr {
    border-bottom: 2px solid #2c9db7;
  }
  .ordersumsplit {
    border-bottom: 1px #d9d9d9;
  }
  .couponvalid {
    color: #1eab15;
  }
  .couponvalid-top {
    color: #1eab15;
    font-size: 12px;
    margin-left: 5px;
  }
  .couponinvalid {
    color: #f5365c;
  }
  .changeplan {
    color: #2c9db7;
    cursor: pointer;
  }
}

///------------------------profile view--------------------------------------------
#profileview {
  .ribbon-wrapper-5 {
    z-index: 2;
    width: 106px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    left: -6px;
  }

  .ribbon-5 {
    z-index: 2;
    font: bold 11px Sans-Serif;
    line-height: 10px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 8px 0;
    left: -33px;
    top: 26px;
    width: 150px;
    background-color: #2c9db7;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 1.1px;
  }

  .ribbon-5:before,
  .ribbon-5:after {
    content: "";
    border-top: 4px solid #4e7c7d;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
  }

  .ribbon-5:before {
    left: 0;
  }

  .ribbon-5:after {
    right: 0;
  }
}
// --------------------------------------------Cart---------------------------------------------
.badges-res {
  width: 75%;
  height: 2rem;
  padding: 0.6rem;
  border: 1px solid #2c9db7;
  color: #2c9db7;
}
.badges-res1 {
  width: 75%;
  height: 2rem;
  padding: 0.6rem;
  border: 1px solid #46b88f;
  color: #2c9db7;
}
.pri-data {
  position: absolute;
  top: 3.4rem;
  left: 2rem;
  @media only screen and (max-width: 900px) {
    position: relative !important;
    top: -1.6rem !important;
    left: 0px !important;
  }
}
.pinp {
  padding: 2.7rem 12rem 1.5rem 1rem;
  @media only screen and (max-width: 900px) {
    padding: 0px 65px !important;
  }
  @media screen and (max-width: 1900px) and (min-width: 1640px) {
    padding: 2.7rem 8rem 1.5rem 1rem !important;
  }
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    padding: 2.7rem 5rem 1.5rem 1rem !important;
  }
}
.pri-rate {
  position: relative;
  top: -24px;
  left: 3rem;
}
.p-top {
  margin-top: -35px !important;
  @media only screen and (max-width: 900px) {
    margin-top: 0px !important;
  }
}
.cart-r {
  padding: 1rem;
  height: 11rem;
  @media only screen and (max-width: 900px) {
    height: auto !important;
  }
}
.sm-vis {
  display: block;
  @media only screen and (max-width: 900px) {
    display: none !important;
  }
}
.sm-vis1 {
  display: none;
  @media only screen and (max-width: 900px) {
    display: block !important;
  }
}
.res-mob-01 {
  @media only screen and (max-width: 900px) {
    padding: 1rem 0px 0px !important;
  }
}
.ml60 {
  @media only screen and (max-width: 900px) {
    margin-left: -60px !important;
  }
}
.res-c {
  @media only screen and (max-width: 900px) {
    text-align: center !important;
  }
}
.ml4i {
  margin-left: 1.5rem !important;
  @media only screen and (max-width: 900px) {
    margin-left: 0px !important;
  }
}
.ml25 {
  @media only screen and (max-width: 900px) {
    margin-left: -40px !important;
    .pri-rate {
      left: 1rem !important;
    }
  }
}
.l {
  @media only screen and (max-width: 900px) {
    left: 0.5rem !important;
  }
}
// .fav-cl2{
//     position: relative;
//     background: #f8f8f8;
//     width: 25px;
//     color: #2c9db7;
//     top: 15px;
//     right: -147px;
//     border: 1px solid #2c9db7;
//     border-radius: 50%;
//     padding: 1px 6px;
//     z-index: 3;
//     text-align: center;
// }
// .B-prod1{
//   width: 10rem;
//   height: 3rem;
//   padding-top: 17px;
//   font-weight: 700;
//   font-size: 0.9000rem !important;
//   background-color: #2c9db7;
//   color: #fff;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
// }
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 600px;
}
.advanced {
  position: relative;
  top: 11px;
  left: 1rem;
  transition: background-color 0.3s linear;
  transition-delay: 0.15s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  border-radius: 0.25rem !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  border-color: rgba(255, 255, 255, 0.9) !important;
  color: rgba(0, 0, 0, 0.9) !important;
}
.advancedClear {
  position: relative;
  top: 13px;
  left: -15px;
  transition: background-color 0.3s linear;
  transition-delay: 0.15s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  border-radius: 0.25rem !important;
  // background-color: rgba(255, 255, 255, 0.9)  !important;
  border-color: rgba(255, 255, 255, 0.9) !important;
  color: rgba(0, 0, 0, 0.45) !important;
  box-shadow: none;
  z-index: 99;
  opacity: 0;
}
// .advancedClear:hover {
//   //box-shadow: 0 4px 6px rgb(50 50 93 / 11%);
// }
.TopSearch {
  input {
    padding: 1rem;
    border-radius: 2rem;
    height: 46px;
    width: 750px !important;
    border: 0 solid;
    color: rgba(0, 0, 0, 0.9);
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s linear;
    transition-delay: 0.15s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    @media only screen and (max-width: 1023px) and (min-width: 765px) {
      width: 423px !important;
      margin-left: -8rem !important;
    }
    @media only screen and (max-width: 1023px) and (min-width: 765px) {
      width: 500px !important;
    }
    @media only screen and (max-width: 1340px) and (min-width: 1200px) {
      width: 650px !important;
    }
  }
  input:focus {
    @media only screen and (device-width: 1440px) {
      width: 750px !important;
    }
    @media only screen and (max-width: 1023px) and (min-width: 765px) {
      width: 500px !important;
    }
    @media only screen and (max-width: 1340px) and (min-width: 1200px) {
      width: 650px !important;
    }

    width: 850px !important;
    box-shadow: none !important;
    .advanced {
      left: 600px;
    }
    .advancedClear {
      left: 600px;
    }
  }

  input::placeholder {
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.8;
  }
  .ant-input-group-addon {
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
    background-color: #eaf5f8;
    left: 0px;
    .anticon {
      line-height: 2;
    }
  }
  .ant-input-group-addon:hover {
    button {
      background-color: transparent !important;
    }
  }
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #eaf5f8 !important;
  }
  group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ant-input-search
    .ant-input:focus
    + .ant-input-group-addon
    .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #eaf5f8 !important;
  }
  .ant-input-search-button:hover,
  .ant-input-search-button:focus {
    z-index: 1;
    background-color: #eaf5f8 !important;
  }
  button {
    background-color: transparent;
    border: none;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }
}

.form-inline .TopSearch input {
  @media screen and (max-width: 1023px) {
    margin-left: 0 !important;
  }
  @media only screen and (max-width: 860px) and (min-width: 765px) {
    width: 200px !important;
    font-size: 12px !important;
  }
  @media only screen and (max-width: 450px) and (min-width: 300px) {
    width: 200px !important;
    font-size: 12px !important;
  }
}
.form-inline .TopSearch input:focus {
  @media only screen and (max-width: 860px) and (min-width: 765px) {
    width: 250px !important;
  }
  // @media only screen and (max-width:500px) and (min-width:300px) {
  //   width: 100px !important;
  // }
  @media only screen and (max-width: 1199px) and (min-width: 1024px) {
    width: 400px !important;
  }
  @media only screen and (max-width: 1000px) and (min-width: 860.96px) {
    width: 400px !important;
  }
}

.ant-select-auto-complete {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-select {
  width: auto !important;
}

.active-Plan {
  color: #2c9db7 !important;
}
.active-dis {
  color: #aaaaaa !important;
}
.active-Plan-hr {
  width: 67% !important;
}
// --------------------------------------------Searchtab ------------------------------------------------
.list-tab {
  .list-main1 {
    width: 20%;
    // height: 8rem;
    margin-right: 10px;
    @media only screen and (max-width: 600px) {
      width: 100% !important;
    }
  }
  .nav-link {
    width: 100%;
    // height: 8rem;
    padding: 20px;
    background-color: #e9ecef;
    @media only screen and (max-width: 600px) {
      width: 100% !important;
    }
    @media only screen and (device-width: 1024px) {
      padding: 10px 0px !important;
    }
    // @media only screen and (device-width: 768px) {
    //   padding: 0px !important;
    // }
  }
}
// -----------------------------------------import/export---------------------------------------------------
.imprt {
  height: fit-content;
  @media only screen and (max-width: 600px) {
    height: auto !important;
  }
  .mt2-5 {
    margin-top: 2.5rem;
  }
}
.card-lift--hover:hover {
  transform: translateY(-20px);
  transition: all 0.15s ease;
}
.top-import {
  width: 100%;
  height: 30rem;
  background-color: #11cdef;
  h4,
  h1 {
    color: #fff;
  }
  .clip-div {
    height: 10rem;
    clip-path: polygon(100% 0, 0 0, 0 100%);
    background-color: #2c9db7;
  }
  @media screen and (max-width: 425.96px) {
    .businet ul {
      text-align: left;
    }
  }
}
.imp-card {
  position: relative;
  top: -6rem;
}
.imp-doc {
  border: 1px solid #b3b3b3;
  height: 7rem;
  padding: 0.5rem;
  border-radius: 10px;
}
.img-impo {
  width: 100%;
  height: 100%;
}
.success-i {
  font-size: 3rem;
  color: #2dce89;
}
.fail-i {
  font-size: 3rem;
  color: #ce3a2d;
}
.buz-01 {
  .MuiAutocomplete-popperDisablePortal {
    position: absolute;
    width: 255px !important;
  }
}

//----------------------Vendor Dashboard------------------------------
#bodashboard {
  .fire {
    color: #f73718;
  }
  .borders {
    border-left: 6px solid #2c9db7;
  }
  .CountBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: flex-start;
  }
  .linkedin {
    background-color: #0a66c2 !important;
    color: white;
  }
  .res-link {
    margin-left: -3rem !important;
    @media only screen and (device-width: 1440px) {
      margin-left: -3rem !important;
    }
  }
  .tit-all {
    @media only screen and (device-width: 1366px) {
      font-size: 1rem !important;
    }
    @media only screen and (device-width: 1360px) {
      font-size: 1rem !important;
    }
  }
  .res-plan {
    font-size: 1.2rem;
    @media only screen and (device-width: 1440px) {
      font-size: 1rem !important;
    }
    @media only screen and (device-width: 1366px) {
      font-size: 0.9rem !important;
    }
    @media only screen and (device-width: 1360px) {
      font-size: 0.9rem !important;
    }
  }
  .comp_count {
    font-size: 2.5rem;
    @media only screen and (device-width: 1440px) {
      font-size: 1.5rem !important;
    }
    @media only screen and (device-width: 1366px) {
      font-size: 1.5rem !important;
    }
    @media only screen and (device-width: 1360px) {
      font-size: 1.5rem !important;
    }
    @media only screen and (max-width: 1680px) and (min-width: 1441px) {
      font-size: 2rem !important;
    }
  }
  .res-h2 {
    @media only screen and (max-width: 1440px) {
      font-size: 0.9rem !important;
    }
    @media only screen and (device-width: 1366px) {
      font-size: 0.8rem !important;
    }
    @media only screen and (device-width: 1360px) {
      font-size: 0.8rem !important;
    }
    @media only screen and (max-width: 1680px) and (min-width: 1441px) {
      font-size: 1rem !important;
    }
  }
  .chart {
    height: 400px;
  }
  thead th {
    font-size: 0.8125rem;
    text-transform: capitalize;
    background-color: white !important;
  }
  .rightCard {
    position: relative;
    top: -30px;
    border-top: 1px solid #e9ecef;
  }
}
.overall {
  height: auto;
  border: 1px #ccc solid;
  border-radius: 3px;
  padding: 1rem;
}
.overall:active {
  height: auto;
}
.fav-cl1 {
  position: relative;
  background: #f8f8f8;
  color: #2c9db7;
  top: 15px;
  left: 93%;
  border: 1px solid #2c9db7;
  border-radius: 50%;
  padding: 1px 6px;
  z-index: 3;
  /* text-align: center; */
  width: 26px;
  height: 27px;
  /* float: right; */
  cursor: pointer;
}
.txt-elps {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
// ------------------------------------------Documents-------------------------------------
.ofc-doc {
  padding: 5px;
  background: #fff;
  //width: 155px;
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 0.375rem;
  overflow: visible;
  margin: 5px;
  box-shadow: 0 0 15px #0000001a;
  display: inline-block;
  border: 1px solid #b8b8b8;
  @media only screen and (device-width: 2560px) {
    width: 230px;
    height: 230px;
  }
}
.doc-image {
  border-radius: 0.375rem;
  width: 100%;
  height: 100%;
  background: #eee;
  overflow: hidden;
  font: 700 20px Sans-Serif;
  text-transform: uppercase;
}
.doc-name {
  text-align: -webkit-center;
  h4 {
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// ----------------------- guest prod list ----------------------------
.brnd-log {
  border: 1px solid #adb5bd;
  width: 55px;
  top: 10px;
  left: 10px;
  padding: 0.5rem;
  border-radius: 15px;
  height: 55px;
  position: absolute;
}
.brnd-log:hover {
  background-color: #ffffff78;
  border: 1px solid #ffff;
  opacity: 1;
}
.badge-1 {
  width: 100%;
  height: 1.5rem;
  padding: 8px;
  background-color: #2c9db7 !important;
  border-color: #2c9db7 !important;
  color: #ffff;
}
.awd-list {
  border: 1px solid #c4c7ca;
  padding: 10px;
  border-radius: 8px;
  background-color: #d7d7d7;
}
.awd-bdy {
  height: 17rem;
  overflow-y: scroll;
}
.mt1-5 {
  margin-top: -0.15rem;
}
.comp-pics {
  img {
    width: 100%;
    object-fit: contain;
  }
}
.comp-detpic {
  // border: 1px solid #b8b8b8;
  width: 30%;
  height: 26%;
  top: 16rem;
  left: 2.5rem;
  border-radius: 5px;
  position: absolute;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    top: 17rem !important;
  }
  .comp-detpich {
    margin-top: 1rem;
    @media only screen and (max-width: 1600px) and (min-width: 900px) {
      margin-top: 1.5rem !important;
    }
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  .list-pic {
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
  }
}
.fact-icon {
  width: 70px;
  height: 70px;
  padding: 0.4rem;
  img {
    width: 100%;
    object-fit: contain;
  }
  @media screen and (device-width: 1024px) {
    width: 60px !important;
  }
}
.fa-s {
  // font-size: 43px;
  font-size: 32px;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    // font-size: 34px !important;
    font-size: 25px !important;
  }
  @media screen and (max-width: 426px) {
    font-size: 15px !important;
  }
  @media screen and (max-width: 769px) {
    font-size: 19px !important;
  }
}
.right-arrow {
  position: absolute;
  right: 1rem;
  top: 42%;
  font-size: 35px;
  color: #adb5bd;
  z-index: 100;
  cursor: pointer;
  transition: ease 0.3s;
  @media only screen and (max-width: 600px) {
    right: 1rem !important;
  }
}
.left-arrow {
  position: absolute;
  left: 1rem;
  top: 42%;
  font-size: 35px;
  color: #adb5bd;
  cursor: pointer;
  transition: ease 0.3s;
  @media only screen and (max-width: 600px) {
    left: 1rem !important;
  }
}
.right-arrow:hover,
.left-arrow:hover {
  font-size: 48px;
  top: 40%;
  color: #5cbcb1;
  cursor: pointer;
}
.algn {
  position: absolute;
  left: 1.4rem;
  top: 5.79rem;
  background-color: #ffff;
}
.ch-edit {
  position: absolute;
  right: 1.5rem;
}
.ch-edit1 {
  position: absolute;
  right: 3rem;
  margin-top: 2.5rem;
}
.prod-slide {
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  padding: 1rem;
  .slide_img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.mres1 {
  @media only screen and (max-width: 600px) {
    margin-right: -1rem !important;
    width: 75% !important;
    height: 3rem !important;
  }
  @media only screen and (max-width: 450px) {
    margin-right: 0 !important;
    width: 75% !important;
    height: 2.6rem !important;
  }
}
.mres2 {
  @media only screen and (max-width: 600px) {
    z-index: 1 !important;
    margin-top: 1.5rem !important;
    margin-right: -0.5rem !important;
  }
  @media only screen and (max-width: 600px) {
    z-index: 1 !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
  }
}
.MuiStep-horizontal {
  padding-left: 8px;
  padding-right: 8px;
  @media only screen and (max-width: 600px) {
    padding-left: 0px !important;
    width: 70px !important;
  }
}
.MuiStepper-root {
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
}
.resm6 {
  @media only screen and (max-width: 1400px) and (min-width: 900px) {
    flex: 18% !important;
    max-width: 22% !important;
  }
  @media only screen and (max-width: 1600px) and (min-width: 1401px) {
    flex: 19.66667% !important;
    max-width: 19.66667% !important;
  }
}
.title1 {
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  // padding: 26px 0;
  border-bottom: 1px solid #e6e6e6;
}
// #login_content {
//   // width: 1024px;
// }

.subtitle {
  font-size: 25px;
  line-height: 35px;
  text-align: left;
  padding: 0;
}
#left_content {
  width: 512px;
  border-right: 1px solid #e6e6e6;
  // padding: 45px 79px 0 43px;
  padding: 1.5rem;
}
#right_content {
  width: 511px;
  // padding: 45px 70px 0 76px;
  padding: 1.5rem;
}
.description {
  font-size: 15px !important;
  line-height: 24px !important;
  margin-top: 12px !important;
  color: #000000 !important;
  @media only screen and (min-width: 2560px) {
    font-size: 1.5rem !important;
  }
}
.playbtn3 {
  position: absolute;
  top: 13%;
  left: 10%;
  color: #fff;
  padding: 1rem;
}
.buytext45 {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  height: 8.5rem;
  @media only screen and (device-width: 2560px) {
    height: 15.5rem !important;
  }
}
.buytext45:focus {
  width: 100% !important;
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}
.bi-text{
  position: absolute;
    text-align: center;
    left: 0;
    top: 55px;
    margin: auto;
    width: 100%;
}
#NewSub {
  .background {
    padding: 0 25px 25px;
    position: relative;
    width: 100%;
  }

  @media (min-width: 900px) {
    .background {
      padding: 0 0 25px;
    }
  }

  .Pricecontainer {
    margin: 0 auto;
    padding: 50px 0 0;
    max-width: 960px;
    width: 100%;
  }

  .panel {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 25px;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  .pricing-table {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
      0px 20px 31px 3px rgba(0, 0, 0, 0.09),
      0px 8px 20px 7px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 900px) {
    .pricing-table {
      flex-direction: row;
    }
  }

  .pricing-table * {
    text-align: center;
    text-transform: uppercase;
  }

  .pricing-plan {
    border-bottom: 1px solid #e1f1ff;
    padding: 25px;
  }

  .pricing-plan:last-child {
    border-bottom: none;
  }

  @media (min-width: 900px) {
    .pricing-plan {
      border-bottom: none;
      border-right: 1px solid #e1f1ff;
      flex-basis: 100%;
      padding: 25px 50px;
    }

    .pricing-plan:last-child {
      border-right: none;
    }
  }

  .pricing-img {
    margin-bottom: 25px;
    max-width: 100%;
  }

  .pricing-header {
    color: #888;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .pricing-features {
    color: #016ff9;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 50px 0 25px;
  }

  .pricing-features-item {
    border-top: 1px solid #e1f1ff;
    font-size: 14px;
    line-height: 1.5;
    padding: 15px 0;
  }

  .pricing-features-item:last-child {
    border-bottom: 1px solid #e1f1ff;
  }
  .contain {
    @media (min-width: 1200px) {
      max-width: 1440px !important;
    }
  }
  .planmarg {
    .pricing-features-item {
      text-transform: capitalize !important;
    }
  }
  .pricing-price {
    color: #3e98c7;
    display: block;
    font-size: 32px;
    font-weight: 700;
  }

  .pricing-button {
    border: 1px solid #9dd1ff;
    border-radius: 10px;
    color: #3e98c7;
    display: inline-block;
    margin: 25px 0;
    padding: 15px 35px;
    text-decoration: none;
    transition: all 150ms ease-in-out;
  }

  .pricing-button:hover,
  .pricing-button:focus {
    background-color: #e1f1ff;
  }

  .pricing-button.is-featured {
    background-color: #48aaff;
    color: #fff;
  }

  .pricing-button.is-featured:hover,
  .pricing-button.is-featured:active {
    background-color: #269aff;
  }
  .pricFirst {
    padding: 25px 10px !important;
    margin-left: -1.9rem !important;
    min-width: 50vh !important;
  }
  // .planTitle {
  //   //color: ;
  // }
  .planmarg {
    margin-top: 18px !important;
  }
  table {
    text-align: center;
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
      0px 20px 31px 3px rgba(0, 0, 0, 0.09),
      0px 8px 20px 7px rgba(0, 0, 0, 0.02);
    background: white;
  }
  .table th {
    border-bottom: none;
    padding-top: 3rem;
  }
  .table td {
    border-right: 1px solid #e9ecef;
  }
  .table td {
    p {
      border-bottom: 1px solid #e1f1ff;
      padding-bottom: 1rem;
      //color: #3e98c7;
      text-transform: capitalize !important;
    }
    padding: 0px 30px;
    border-top: none;
  }
  .titl {
    color: black !important;
  }
}
#NewSub .table td p {
  border-bottom: 1px solid #e1f1ff;
  padding-bottom: 1rem;
  text-transform: none !important;
}
.slick-track {
  height: auto !important;
}
.prodheadfontsize {
  font-size: 1rem;
  @media screen and (min-width: 2560px) {
    font-size: 2rem !important;
  }
}
.regis {
  background-color: #e87d2e !important;
  border-color: #e87d2e;
  color: #ffff;
}
.manag {
  background-color: #3fa1b7 !important;
  border-color: #3fa1b7;
  color: #ffff;
}
.selectprof {
  button,
  .dropdown,
  .dropdown-menu {
    width: 100% !important;
  }
}
.logmarginbotom {
  margin-top: -15rem !important;
  @media only screen and (device-width: 1360px) {
    margin-top: -8rem !important;
    padding-bottom: 0px !important;
  }
  @media only screen and (device-width: 1366px) {
    margin-top: -8rem !important;
    padding-bottom: 0px !important;
  }
  @media only screen and (device-width: 1024px) {
    margin-top: -12rem !important;
  }
  @media only screen and (min-width: 0px) and (max-width: 769px) {
    margin-top: 0rem !important;
  }
}
.loginpad {
  @media (min-width: 992px) {
    padding-bottom: 18% !important;
    padding-top: 4.5rem !important;
  }
  @media only screen and (device-width: 1600px) {
    padding-bottom: 14% !important;
    padding-top: 2rem !important;
  }
  @media only screen and (device-width: 1360px) {
    padding-bottom: 8% !important;
    padding-top: 1rem !important;
  }
  @media only screen and (device-width: 1366px) {
    padding-bottom: 8% !important;
    padding-top: 1rem !important;
  }
}

.keyProductSmall {
  width: 190px !important;
  height: 200px !important;
  @media screen and (min-width: 2560px) {
    width: 230px !important;
    height: 300px !important;
  }
}
.keyheight {
  height: 70% !important;
}
.signupcontainer {
  margin-top: -19rem !important;
  @media only screen and (device-width: 768px) {
    margin-top: -3rem !important;
  }
}
.signuptophead {
  @media (min-width: 992px) {
    padding-bottom: 18rem !important;
  }
}
.heightfix {
  img {
    margin-top: 0rem !important;
  }
}
.prodheight {
  width: 190px;
  height: 170px;
}
.act-bdy {
  max-height: 32rem;
  overflow-y: scroll;
  @media only screen and (device-width: 1366px) {
    max-height: 31rem !important;
  }
  @media only screen and (device-width: 1360px) {
    max-height: 31rem !important;
  }
}
.i-insta {
  background-image: linear-gradient(
    to right top,
    #f9f91b,
    #ffd600,
    #ffb200,
    #ff8d00,
    #fa6700,
    #fb4d27,
    #f72f3d,
    #ee0050,
    #e20077,
    #c61b9a,
    #9b3cb6,
    #5a50c7
  );
}
.i-link {
  background-color: #0077b5 !important;
  border-color: #0077b5 !important;
}
.i-linkDisabled {
  background-color: grey !important;
  border-color: grey !important;
}

//responsive

@media (min-width: 320px) and (max-width: 480px) {
  .right-search {
    top: -4rem !important;
    left: 8px !important;
  }
  .right-sort {
    top: -4rem !important;
    // left: 75% !important;
  }
.meeting-dialog{
  min-width:96% !important;margin:auto;
}
  .quotes-slide {
    padding: 0.2rem !important;
    //height: 50vh !important;
  }
  .testimonial {
    .card-body {
      padding: 0px !important;
    }
  }
  .testimonial .content p {
    //height: 20vh !important;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.6rem !important;
  }
  cite,
  .fontsi {
    font-size: 0.6rem !important;
  }
  .heroButtons {
    left: 1% !important;
  }
  .searchSection {
    left: 1% !important;
  }
  .serbtn {
    width: 120px !important;
  }
  #NewSub {
    overflow-y: scroll;
  }
  .gsbuyer {
    bottom: 1% !important;
    @media (min-width: 320px) and (max-width: 480px) {
      bottom: -34% !important;
    }
  }
  .topSearchge {
    left: 3% !important;
  }
  .TopSearch input {
    width: 350px !important;
  }
  .signupcontainer {
    margin-top: -6rem !important;
    @media all and (min-width: 319px) and (max-width: 599px) {
      margin-top: -3rem !important;
    }
  }
  .logmarginbotom {
    margin-top: -8rem !important;
    @media screen and (min-width: 0px) and (max-width: 769px) {
      margin-top: 0rem !important;
    }
  }
  .list-adtab {
    padding-top: 0px !important;
    margin-right: 0rem !important;
  }
}

@media screen and (max-width: 1199.96px) {
  .TopSearch input {
    width: 300px !important;
  }
}
.TopSearch input:focus {
  @media screen and(min-width:1050px) and (max-width: 1199.96px) {
    width: 500px !important;
  }
  @media only screen and (device-width: 1024px) {
    width: 400px !important;
  }
}
@media only screen and (device-width: 1024px) {
  .topSearchge {
    left: 26% !important;
  }
}
.res-prod {
  .wid33 {
    @media only screen and (max-width: 600px) {
      width: 100% !important;
    }
  }
}
.pub-prod {
  @media only screen and (max-width: 600px) {
    margin-top: 1rem !important;
  }
}
.namedesc {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4c4c4c;
  width: 6rem;
  cursor: pointer;
}
.dictor {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: auto;
  cursor: pointer;
}
.clearfilter {
  position: absolute;
  right: 10px;
  top: -1.9rem;
}

.box {
  transition: box-shadow 0.3s;
  width: 300px;
  height: 500px;
  margin: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  float: left;
}
.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

@media (max-width: 1000px) {
  .content {
    width: 46%;
  }
}
@media (max-width: 750px) {
  .content {
    width: 100%;
  }
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px;
  position: relative;
  .brandImg {
    border-radius: 0.375rem;
    width: 65px;
    min-height: 65px;
    background: #fff;
    position: absolute;
    left: 10px;
    top: 10px;

    border: solid 1px #eeeeee;
    img {
      border-radius: 0.375rem;
      width: 100%;
      height: 100%;
      background: #fff;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 450px) {
    margin: auto !important;
  }
}

.gallery_content:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2) !important;
}
.amr{
  margin-right: 15px;
  font-size: 12px;
}
.pmb{
  margin-bottom: -1.5rem;
}
.vend-size{
  font-size: 14px;
}
.pts{
  font-size: 14px;
}
.n-ppost-name {
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: -200px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  opacity: 0;
  -webkit-transform: translate3d(0, -15px, 0);
          transform: translate3d(0, -15px, 0);
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.4;
  visibility: hidden;
  pointer-events: none;
}
  
.n-ppost:hover + .n-ppost-name {
  padding:0.3rem;
  border:1px solid rgb(219, 219, 219);
  border-radius: 10px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.gallery_content {
  //  width: 24%;
  //   margin: 15px;
  box-sizing: border-box;
  float: left;
  text-align: center;
  border-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  transition: 0.4s;
  width: 100%;

  .gallery_img {
    //width: 200px;
    width: 100%;
    height: 250px;
    text-align: center;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }
  .react-multiple-carousel__arrow:hover{
    background: none;
  }
  .react-multiple-carousel__arrow::before{
    font-size: 18px !important;
    color: #000000 !important;
  }

  .react-multiple-carousel__arrow--left{
    left: calc(4% + -10px);
  }
  .react-multiple-carousel__arrow--right{
    right: calc(4% + -10px);
  }

  .react-multiple-carousel__arrow{
    height: 24px;
    width: 24px;
    min-height: 20px;
    min-width: 20px;
    background: none;
  }

  .gallery_img_visib {
    //width: 200px;
    width: 100%;
    height: 125px;
    text-align: center;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }
  h3 {
    white-space: nowrap;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 15px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: auto;
  }
  .awards_thumb {
    width: 35px;
    height: 35px;
    img {
      width: 100%;
      height: 100%;
      background: #fff;
      object-fit: contain;
      border-radius: 0.375rem;
    }
  }
}

.productIcon1 {
  padding-right: 4px;
  i {
    font-size: 20px;
    position: relative;
    opacity: 0.7;
  }

  .Icon1,
  .Icon2,
  .Icon3,
  .Icon4 {
    cursor: pointer;
    position: relative;
    width: 40px;
    padding: 9px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgb(238, 238, 238);
  }
  .Icon1:hover,
  .Icon2:hover,
  .Icon3:hover,
  .Icon4:hover {
    i {
      opacity: 1;
    }
  }
  .Icon2 {
    padding: 8px;
  }
  .Icon3 {
    padding: 10px;
  }
}
.socialbox {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f;
  text-align: left;
}
.socialboxed {
  .MuiTooltip-popperArrow {
    width: 70%;
    pointer-events: all !important;
  }
}
.borderdivid {
  border-bottom: 1px solid #e9ecef;
}
.inactive {
  background-color: #e9ecef;
  width: 104%;
  height: 5rem;
  //top: -10px;
  text-align: center;
}
.left-20px {
  left: -20px;
}
.padleft {
  padding-left: 20px;
}
.publicproduct {
  .gallery_content {
    box-shadow: 0 10px 30px #0000000d;
    border: none !important;
    .prodcontent {
      h3 {
        margin-top: 0px !important;
        border-bottom: none !important;
      }
      p {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }
}
.dropdownfirst {
  width: 74%;
  display: inline-block;
  white-space: nowrap;
  margin-left: 1rem !important;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #32325d;
  @media only screen and (device-width: 320px) {
    width: 70% !important;
    margin-left: 6px !important;
  }
}
.dropdownsize {
  width: 133%;
}
.dropdownwidthfirst {
  width: 100%;

  @media only screen and (device-width: 320px) {
    width: 100% !important;
  }
}
.dropdownwidthseco {
  width: 100%;
}
.btnfirst,
.btnfirst:hover {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  color: #32325d !important;
  width: 100%;
  text-align: left;
  background-color: #fff !important;
  padding: 0.3rem 0.5rem !important;
}
// .btnfirst::after{
//   right: 10px;
//     top: 23px;
//     position: fixed;
// }

.fs1 {
  font-size: 1rem !important;
  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: 0 !important;
    margin-top: 0.5rem;
  }
}

.company_profile_input-grp {
  @media only screen and (max-width: 500px) {
    display: block !important;
  }
  .input-group-prepend {
    @media only screen and (max-width: 500px) {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
    }
  }
  .MuiFormControl-root {
    @media only screen and (max-width: 500px) {
      width: 100% !important;
    }
  }
}

// ------------------------------------------termsandconditions-------------------------------------
.tandc {
  top: 0px !important;
  text-decoration: underline;
}
.ant-select-clear {
  font-size: 20px;
  opacity: 1;
  right: -45%;
  margin-top: -11px;
}

.planBorder {
  border-bottom: 2px solid #2c9db7;
}
.borderColor {
  border-bottom: 2px solid #e9ecef !important;
  padding-bottom: 0.5rem !important;
}
.planBox {
  //  box-shadow: 0 0 5px rgba(33,33,33,.2) !important;
  //background-color: #F4F7FE;
  background-color: #fafbff;
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  padding: 0.5rem;
  .col-lg-12 {
    border-bottom: 2px dashed #e9ecef !important;
  }
  .col-lg-12:last-child {
    border-bottom: none !important;
  }
}
.padInner {
  padding-right: 10px;
  padding-left: 10px;
}
.planLeft {
  padding: 0.5rem;
  //background-color: #585c5f;
  background-color: #f3f6fd;
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  .col-lg-12 {
    border-bottom: 2px dashed white !important;
  }
  .col-lg-12:last-child {
    border-bottom: none !important;
  }
}
.iconContainer {
  cursor: pointer;
  position: relative;
  width: 20px;
  padding: 3px;
  padding-left: -11px;
  margin-left: -10px;
  display: inline-block;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  i {
    font-size: 14px;
    position: absolute;
    opacity: 0.7;
    color: rgb(82, 95, 127);
  }
}
.stickyPay {
  position: sticky;
  top: 0;
  z-index: 1;
}
.listImg {
  width: 30px !important;
  height: 30px !important;
  margin-right: 0.5rem;
}
.contactTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Bgbg {
  background-color: #f8f9fe;
}
.dropdownText {
  // width: 80%;
  // // white-space: nowrap;
  // // overflow: hidden;
  // // text-overflow: ellipsis;
  // display: inline-block;
  // vertical-align: top;
  // line-height: 1;
  // // margin-bottom: -20px;
  // // border: 1px solid red;
  // font-weight: 700;

  width: 80%;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  /* height: 3rem; */
  padding-top: 5px;
  margin-bottom: 10px;
  font-weight: 700;
  text-align: left;
}
.dropdownText1 {
  line-height: 1 !important;
}
.tol {
  z-index: 9999 !important;
}
.outerli {
  height: 600px !important;
}
.rightar-wh {
  color: white !important;
}
.rightar-wh:hover {
  color: white !important;
}
.leftar-wh {
  color: white !important;
}
.leftar-wh:hover {
  color: white !important;
}
.conn-cont {
  .contactTitle {
    line-height: 1.2 !important;
  }
}
#myIntroVideo {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  border: 1px solid #b8b8b8;
}
.connectedCheck {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  border-color: #4fd69c;
  color: #4fd69c;
}
.rated {
  font-size: 1rem !important;
  border-color: #ffe234 !important;
  color: #ffe234 !important;
  position: absolute;
  top: 8px;
}
.starrated {
  font-size: 1rem !important;
  border-color: #ffe234 !important;
  color: #ffe234 !important;
  top: 8px;
}
.unrated{
  color: lightgrey !important;
}
.ratedvalue {
  position: absolute;
  top: 5px;
  right: 60px;
  font-size: 1.5rem;
  color: #0077b5;
  font-weight: 600;
  line-height: 1.5;
}
.starratedvalue{
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 600;
  line-height: 1.5;
}
.Checkmark {
  position: absolute;
  right: -2.2rem;
  top: 1rem;
  background: #f7fafe;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.Checkspan {
  margin-top: 0.4rem;
  margin-left: 0.5rem;
}
.ellipsisText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  display: block !important;
  padding-top: 0.8rem;
}
.invalid-number-message {
  top: 60px !important;
  background: none !important;
}
#certificateName {
  height: 56px;
}
#Vat {
  height: 56px;
}
.didy {
  font-size: 1.8rem;
  color: #2c9db7;
}
.didy1 {
  font-size: 1.5rem;
  margin-right: 5px;
  color: #2c9db7;
}

.handshak {
  .tooltip-inner {
    background-color: lightgrey;
    color: #3f5155;
  }

  .tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: lightgrey;
    color: #2dce89;
  }
}
@media only screen and (device-width: 2048px) {
  .VideoSection {
    height: 52vh !important;
  }
}
.planBox {
  p {
    height: 3rem !important;
  }
}
.planLeft {
  p {
    height: 3rem !important;
  }
}

@media  screen and (max-width:400px) {
  p{
    height: fit-content;
  }
}

.prodinter {
  width: 100%;
  // border: 1px solid red;
  // height: 11rem;
}

.blink {
  animation: blinker 1s linear infinite;
  font-size: 2rem;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.hilight {
  background-color: #eaf5f8;
  height: 6rem;
  text-align: center;
}

//product visibility set  CSS
.galler {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  justify-content: center;
  align-items: center;
  margin: 20px;
  position: relative;
}
.galler_click {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  justify-content: center;
  align-items: center;
  margin: 20px;
  position: relative;
  background-color: #000;
}
.galler_content:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2) !important;
}
.galler_content {
  //  width: 24%;
  //   margin: 15px;
  box-sizing: border-box;
  float: left;
  text-align: center;
  border-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  transition: 0.4s;
  width: 100%;

  .galler_img_visib {
    //width: 200px;
    width: 100%;
    height: 125px;
    text-align: center;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }
  .galler_img_visib_click {
    //width: 200px;
    width: 100%;
    height: 125px;
    text-align: center;
    margin: 0 auto;
    display: block;
    object-fit: contain;
    filter: grayscale(100%);
  }

  h5 {
    white-space: nowrap;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 15px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

#newdashboard {
  .timeline-one-side:before {
    left: -0.7rem !important;
  }
  .timeline-one-side .timeline-step {
    left: -0.7rem !important;
    top: 1.3rem !important;
  }
}
.pointer {
  cursor: pointer;
}
.newdesc {
  font-size: 15px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.counts {
  width: 8rem;
  position: relative;
  height: 8rem;
  border-radius: 50%;
  border: 1px solid #b8b8b8;
}
.countNO {
  position: absolute;
  top: 20%;
  left: 34%;
  font-size: 2rem;
}
.countTEX {
  position: absolute;
  top: 58%;
  left: 20%;
  font-size: 1.2rem;
}
.dashtime::before {
  left: 0.3rem !important;
}

.dashtimestep {
  left: 0.3rem !important;
  top: 0px !important;
}
.Counticon {
  width: 2.5rem;
  height: 2.5rem;
}
.fire {
  color: #f73718;
}
// .timelineDash::before{
//   left: 0.3rem !important;
// }
// .timeline-step1{
//   left: 1rem !important;
// }
.timeline-content {
  margin-left: 50px !important;
}
.planhbtn {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}
#newdash {
  max-height: 350px;
  @media only screen and (min-width: 1920px) {
    max-height: 420px;
  }
  .timelineDash:before {
    left: 0.3rem !important;
  }
  .timelineDash .timeline-step {
    left: 0.3rem !important;
    top: 1.3rem !important;
  }
}
#lstven {
  max-height: 418px;
  .timeline:before {
    border-right: 0px solid #e9ecef;
  }
  .timeline-one-side:before {
    left: 1rem !important;
  }
  .timeline-one-side .timeline-step {
    left: 1rem !important;
    top: 1.3rem !important;
  }
  .timeline-content {
    margin-left: 60px !important;
  }
}
#lstprod {
  max-height: 325px;
  .timeline:before {
    border-right: 0px solid #e9ecef;
  }
  .timeline-one-side:before {
    left: 1rem !important;
  }
  .timeline-one-side .timeline-step {
    left: 1rem !important;
    top: 1.3rem !important;
  }
  .timeline-content {
    margin-left: 60px !important;
  }
}
#lstnews {
  max-height: 100.5rem;
  @media screen and (max-width: 1480px) and (min-width: 900px) {
    max-height: 90.8rem !important;
  }
  @media screen and (max-width: 1680px) and (min-width: 1600px) {
    max-height: 93.5rem !important;
  }
  .timeline-one-side:before {
    left: 1rem !important;
  }
  .timeline-one-side .timeline-step {
    left: 1rem !important;
    top: 1.3rem !important;
  }
  .timeline-content {
    margin-left: 60px !important;
  }
}
#dashslide {
  .slick-prev,
  .slick-next {
    top: 50% !important;
  }
}
// ----------------event-----------------------------
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}
.ribbon span {
  font-size: 0.8rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#aaedf9 0%, #11cdef 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px; // change this, if no border
  right: -29px; // change this, if no border
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.red span {
  // background: linear-gradient(#f70505 0%, #8f0808 100%);
  background: linear-gradient(#f8dc43 0%, #ff8b5a 100%);
}
.red span::before {
  border-left-color: #8f0808;
  border-top-color: #8f0808;
}
.red span::after {
  border-right-color: #8f0808;
  border-top-color: #8f0808;
}

.blue span {
  background: linear-gradient(#7cc2d2 0%, #2c9db7 100%);
}
.blue span::before {
  border-left-color: #1e5799;
  border-top-color: #1e5799;
}
.blue span::after {
  border-right-color: #1e5799;
  border-top-color: #1e5799;
}

.foo {
  clear: both;
}

.bar {
  content: "";
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.baz {
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 2em;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 100px;
  left: 1000px;
}
#event {
  .imprt {
    height: 24rem !important;
  }
  .ovr-flw {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 27%;
    width: 100%;
  }
}
.evebghgt {
  margin-top: -15rem !important;
}
.Evedat1 {
  font-size: 4rem;
  font-weight: 200;
  @media screen and (max-width: 1680px) and (min-width: 900px) {
    font-size: 3rem !important;
  }
}
.meet {
  @media screen and (max-width: 1680px) and (min-width: 900px) {
    margin-top: 13px !important;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    margin-top: 12% !important;
    margin-left: 0 !important;
  }
}
.shadecard {
  @media screen and (max-width: 1680px) and (min-width: 900px) {
    // padding-bottom: 8%;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    //padding-bottom: 80%;
  }
}
.odometer-block {
  font-size: 14px !important;
  margin-top: 1rem !important;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 1680px) and (min-width: 900px) {
    font-size: 9px !important;
  }
}
#Event {
  .list-adtab {
    height: 7rem !important;
  }
}
.drop {
  width: 85%;
  height: 3.5rem;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
}
.Nwsovr {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Nwsovrp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shade {
  box-shadow: 0 10px 30px #0000000d !important;
}

.shade:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2) !important;
}
.chge-hov {
  p {
    color: #000;
  }
}

#ch-list {
  .hide{
    .MuiAutocomplete-listbox {
      height: 6rem !important;
    }
  }
}
.card{
  transition: .3s all ease-in-out;
}
#dashslide .card:hover{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
#ch-list {
  .MuiAutocomplete-listbox {
    height: 16rem !important;
  }
}
.agend {
  background-color: #2c9db7 !important;

  @media screen and (max-width: 500px) {
    padding: 0.5rem !important;
  }

  .card-body {
    @media screen and (max-width: 500px) {
      padding: 0.5rem !important;
    }
  }
}
.tab-agend {
  div {
    border: 1px solid #2c9db7;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
  }
  .active {
    background-color: #2c9db7 !important;
    color: #ffff !important;
  }
}

// ---------------------------------------event travel section note------------------------------------
.eventname {
  font-size: 13px;
  h3 {
    font-weight: bold;
    font-size: 13px;
  }
  p {
    font-size: 13px;
  }
}

// notification-------------------------------
.noti {
  text-align: center;
  position: relative;

  i {
    font-size: 20px;
    margin-top: 10px;
    color: #fff;
    cursor: pointer;
  }
  i:hover {
    color: #ffffff91;
  }
}
.noti-content {
  transition: all 0.5s ease;
  // position: absolute;
  // width: 20%;
  width: 100%;
  background: #fff;
  height: auto;
  float: left;
  z-index: 1;
  right: 77px;
  top: 90px;
  .notilist {
    margin: 20px;
    border: 1px solid #00000054;
    padding: 28px 22px 28px 37px;
    .content {
      list-style: none;
      i {
        position: relative;
        right: 23px;
        cursor: pointer;
        border: 1px solid #d1cccc;
        color: #d1cccc;
        top: 25px;
      }
      h6 {
        font-size: 16px;
        text-transform: capitalize;
      }
    }
  }
}
.list-group-item-action {
  width: 100%;
  float: left;
  list-style: none;
}
.noticlose {
  position: relative;
  top: 24px;
}
.notilogo {
  width: 28px;
  height: 28px;
  background-color: white;
  margin-left: 20px;
}
.notidetail {
  font-size: 13px;
}
.notitime {
  position: relative;
  left: -5px;
  font-size: 14px;
  color: #b1aeae;
}
// .cardcolor{
//   border-left: 5px solid #2c9db7;
// }
.cardcolor:nth-child(odd) {
  border-left: 5px solid #2c9db7;
  float: left;
}
.cardcolor:nth-child(even) {
  border-left: 5px solid #fcf80a;
  float: left;
}
.clk {
  float: right !important;
}
.cardcolor .card-body {
  padding: 3px;
}
@media only screen and (max-width: 768px) and (min-width: 480px) {
  .noticlose {
    left: 12px;
  }
  .meeting-dialog{
    min-width:90% !important ;
  }
  .notidetail {
    text-align: justify;
  }
  .clk {
    float: left !important;
  }
}
.form-group .ql-editor {
  color: #000 !important;
  height: 140px;
}
.buytext4 {
  height: initial !important;
  padding: initial !important;
  border: 0px !important;
}
.test-card{
  width: 90% !important;
  justify-content: center;
  align-items: center;
  margin: 20px;padding: 20px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 5px 0px;
  border-radius: 10px;position: relative;transition: .3s all ease-in-out;
}
.test-card:hover{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.test-card i{
 position: absolute;right: 20px;bottom: 20px;font-size: 30px;color: #ccc;
}
// --------------------eventawards-----------------
.eventaward {
  width: 100%;
  // display: flex;
  // justify-content: center;
  float: left;
  text-align: center;
  margin: 32px 0 28px 0;
  padding: 12px 0 9px 0;
  .eventspan {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 800 !important;
    span {
      width: 8%;
      border-bottom: 3px solid #32325d;
      display: inline-block;
      /* justify-content: center; */
      font-weight: 800 !important;
      float: none;
      position: relative;
      bottom: 12px;
    }
  }
  .eventvideo {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 36px;
  }
  video {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  h2 {
    text-transform: capitalize;
    text-align: center;
    font-size: 28px;
    position: relative;
    width: 100%;
    font-weight: 800 !important;
    margin-bottom: 20px;
  }
  h5 {
    text-align: center;
    font-size: 30px;
    width: 100%;
    color: #2d0b4e;
    font-weight: 800 !important;
    margin-bottom: 20px;
  }
  p {
    text-align: center;
    font-size: 18px;
    width: 100%;
    color: #2d0b4e;
    float: none;
    display: inline-block;
    line-height: initial !important;
    font-weight: 800 !important;
    span {
      font-style: italic;
      text-align: center;
      font-size: 17px;
      width: 100%;
      float: none;
      color: #2d0b4e;
      font-weight: 600;
      margin-bottom: 11px;
    }
  }
  span {
    text-align: center;
    font-size: 18px;
    width: 100%;
    float: left;
    color: #2d0b4e;
    font-weight: 600;
    margin-bottom: 11px;
  }
}
// =======pagenotfound----------------------
.pgimg {
  width: 500px;
  height: 500px;
  float: left;
  position: relative;
  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.pg-content1 {
  float: left;
}
.pg-content {
  float: left;
  padding-top: 7%;
  .pg-a {
    background-color: #ff4200;
    color: #fff;
    padding: 10px;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 11px;
    float: left;
  }
}
.pg-card {
  padding-top: 5%;
}
.pg-a::hover {
  color: #000;
  background-color: #ff4200bf;
}

.str input[type="radio"] {
  display: none;
}
.event-starrating .star {
  cursor: pointer;
  // transition: color 200ms;
  font-size: 25px;
}
.star {
  cursor: pointer !important;
  transition: color 200ms !important;
  font-size: 25px !important;
  margin: 0 3px;
  .titile {
    font-size: 13px;
    border: initial !important;
  }
}
#eye .heart {
  position: absolute;
  /* top: 0; */
  right: 0;
  font-size: 2rem;
  left: 0;
  margin: auto;
  bottom: 70px;
  @media screen and (max-width: 1124px) and (min-width: 800px) {
    bottom: 20px !important;
    top: initial !important;
  }
  @media screen and (max-width: 991.96px) and (min-width: 601px) {
    position: initial !important;
    padding-right: 25px;
    top: 2px !important;
  }
  @media only screen and (max-width: 600px) {
    padding-right: 25px;
  }
}

//SUBSCRIPTION

.planwidth {
  width: 60%;
  @media screen and (max-width: 400px) {
    width: auto;
    text-align: center !important;
  }
}

//SUGGESTION

@media screen and (max-width: 800.96px) {
  .suggestChannelList {
    justify-content: space-between !important;
    margin: 10px !important;
    align-items: center !important;
  }
  .suggestChannelList .badge-secondary {
    font-size: 0.7rem !important;
  }
  .suggestChannelListPvtLtd .col-md-auto {
    width: 100%;
    text-align: center;
    margin: 15px;
  }
  .suggestChannelList .col-md-2,
  .col-md-3,
  .col-md-5 {
    padding: 0 !important;
    text-align: center;
  }
  .suggestionListProgressBar .col-md-4 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .suggestionListProgressBar .progress {
    width: 100%;
  }
}

@media screen and (max-width: 575.96px) {
  .suggestionListProgressBar {
    display: block;
  }
  .activity-right img{
    max-width:30%;
  }
}

@media screen and(max-width:1100px) and (min-width: 780px) {
  #Event .list-adtab {
    width: 19% !important;
  }
}
@media (max-width:991px){
.about-wrapper .activity .pl-0{
  padding-left: 15px !important;
}
.about-wrapper .activity .pr-0{
  padding-right: 15px !important;
}
.founder-card figure{
  width:200px;height:200px;
}
}

.agend .css-13xy2my{
  width:80%;
}
.agend .css-18qulp9{
  min-width: initial;



//MY NETWORK

@media screen and (max-width:761.96px) {
  .right-search{
    position: unset !important; 

  }
  .right-sort{
    position: unset !important;
  }
}




//Suggestion-Vendor

.suggest-ven .list-ven{
  margin-right: 0.3rem !important;
  width: 13% !important;
}

.suggest-ven .list-ven .nav-link{
  padding: 15px !important;
}

.suggest-ven .list-adtab{
  width: 13% !important;
}

.suggest-ven .list-ven h3,h4{
  margin: 0 !important;
}
.suggest-ven .list-ven h4,.list-adtab h4{
  @media screen and (max-width:1600.96px) and (min-width:861.96px) {
    font-size: 0.8rem !important;
  }
}

.suggest-ven .list-ven .awards_thumb{
  padding: 0 !important;
  margin-right: 0 !important;
  text-align: center;
}

.suggest-ven .list-ven .listCompany{
  // margin-top: 0.6rem !important;
  padding: 0 !important;
}

@media screen and (max-width:899.96px) {
  .suggest-ven .list-ven,.list-adtab{
    width: 17% !important;
  }
}

@media screen and (max-width:861.96px) and (min-width:600.96px){
  .suggest-ven .right-search{
    width: 10% !important;
    position: unset !important;
    margin-top: 3rem !important;
    width: 25% !important;
  }

  .suggest-ven .right-sort
  {
    position: unset !important;
    margin-top: 3rem !important;
  }
}

@media screen and (max-width:861.96px) and (min-width:600.96px) {
  .suggest-ven .right-search{
    width: 89% !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
  }

  .suggest-ven .right-sort{
    margin-top: 0 !important;
    // width: 9% !important;
  }

  .suggest-ven .list-adtab{
    width: 25% !important;
  }

  .suggest-ven .list-ven {
    width: 21% !important;
    margin-right: 0.3rem !important;
  }
}
.suggest-ven .list-adtab,
.suggest-ven .list-ven {
  @media screen and (max-width: 600.96px){
    width: 100% !important;
  }
}
.suggest-ven .list-ven {
  @media screen and (max-width: 600.96px){
    margin-right: 0 !important;
  }
}

.suggest-ven .right-search{
  @media screen and (max-width: 786.96px) and (min-width:644.96px){
      width: 87% !important;
    }
    @media screen and (max-width: 644.96px) and (min-width:601.96px){
      width: 84% !important;
    }
}
// @media screen and (max-width: 786.96px) and (min-width: 600.96px){
//   .suggest-ven .right-search{
//     width: 80% !important;
//   }
// }
@media screen and (max-width:769.96px) and (min-width:600.96px){
  .suggest-ven .list-ven{
    margin-right: 0 !important;
    height: 6rem !important;
  }
}

@media screen and (max-width:600.96px){
.suggest-ven .list-ven{
  height: 6rem !important;
}
.suggest-ven .list-ven {
  text-align: center !important;
}

.suggest-ven .list-adtab{
  height: 4rem !important;
}

}

@media screen and (min-width:800.96px) and (max-width:891.96px){
.suggestTitle h1{
  font-size: 20px !important;
}
}


//MY PRODUCT LIST

#MyProduct .list-main{
width: 30%;
}

#MyProduct .nav-item .prodlsact{
position: unset !important;
}

#MyProduct .right-search1{
position:unset !important;
margin-top: 2.5rem !important;
}

#MyProduct .right-sort1{
position: unset !important;
margin-top: 2.5rem !important;
}

#MyProduct .list-tab li:nth-child(2){
  margin-top: 3rem !important;
}

@media screen and (max-width:800.96px) {
  #MyProduct .list-main {
    width: 100% !important;
    margin-right: 0 !important;
}

#MyProduct .list-tab li:nth-child(2){
  width: 100%;
  margin-top: 1.5rem !important;
  text-align: center !important;
}

#MyProduct .right-search1 {
  position: unset !important;
  margin-top: 1rem !important;
  width: 70%;
  margin-bottom: 1rem;
}
#MyProduct .right-sort1 {
  position: unset !important;
  margin-top: 1rem !important;
}

#MyProduct .right-search1 .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
  width: 100% !important;
}
}

@media screen and (max-width:356.96px) {
  #MyProduct .nav-item .prodlsact button:nth-child(1){
    width: 100% !important;
  }
  #MyProduct .nav-item .prodlsact button:nth-child(2){
    width: 100% !important;
    margin-left: 0;
    margin-top: 1rem;
  }
}

@media screen and (max-width:1000.96px) and (min-width:800.96px) {
  #MyProduct .list-main{
    width: 100% !important;
    margin-right: 0 !important;
  }
  #MyProduct .list-tab li:nth-child(2){
    margin-top: 1.5rem !important;
    width: 50% !important;
    text-align: end;
  }
  #MyProduct .right-sort1 {
    margin-top: 1rem !important;
  }
  #MyProduct .right-search1{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    width: 39% !important;
  }
}

@media screen and (min-width:1000.96px) and (max-width:1200.96px){
  #MyProduct .list-main{
    width: 40% !important;
  }
  #MyProduct .right-search1{
    width: 15%;
  }
}

//MY Product-Pending
.MyProduct .res-md h3 {
  width: 100% !important;
}
.MyProduct .res-md {
  padding: 1.5rem !important;
}

@media screen and (max-width:767.96px) {
  .MyProduct .res-md{
    text-align: center;
  }
  .MyProduct .user-card{
    text-align: center;
  }
  .MyProduct .outer-badge_row{
    justify-content: center;
  }
}

@media screen and (min-width:767.96px) {
  .MyProduct .pending_row .user-card .outer-badge_row{
    justify-content: space-between;
  }
  .MyProduct .pending_row div:nth-child(1) div:nth-child(1){
    justify-content: space-between;
  }
}

@media  screen and (min-width: 991.96px){
  .MyProduct .pending_row {
    height: 12rem !important;
  }
 
}

@media screen and (max-width:991.96px){
  .MyProduct .pending_row .user-card{
    height: auto !important;
  }
}

@media screen and (max-width:767.96px){
  .MyProduct .pending_row div:nth-child(2) .row .user-card:nth-child(4) div{
    display: flex;
    justify-content: space-evenly;
  }
}

@media screen and (max-width:991.96px) {
  .MyProduct .res-spa,.res-spa1{
    width: 75% !important;
  }
}

@media screen and (min-width:991.96px) {
  .MyProduct .res-spa1 {
    width: 100% !important;
    font-size: 9px !important;
  }
}

@media screen and (min-width: 991.96px) and (max-width:1199.96px){
  .MyProduct .pending_row {
    height: 20rem !important;
  }
}

@media screen and (min-width:1200px) and (max-width:1500px){
  .outer_badge_xl{
    font-size: 8px !important;
    width: 100% !important;
  }
}

@media screen and (min-width:1200px) and (max-width:1400px) {
  .MyProduct .pending_row .pad-less:nth-child(2) .user-card:nth-child(2) span{
    width: 100% !important;
  }
  .MyProduct .pending_row .pad-less:nth-child(2) .user-card:nth-child(3)
  {
    padding-left: 0 !important;
  }

}


// Event Pending

.event-pending button{
float: none !important;
}

// CompanyProfileDist

@media screen and (max-width:576px) {
.companyDist .row:nth-child(1) .brandCard{
  float: unset;
  width: fit-content; 
  }  
.companyDist .row:nth-child(1) .row:nth-child(1) .col-sm-5{
   text-align: -webkit-center;
  }
}

@media screen and (max-width:400.96px) {
  .companyDistTrade .brand{
    padding: 16px 20px;
  }
  .companyProfileDist .card-body{
    padding: 0.5rem !important;
  }
}

.companyDistTrade .form-group{
  margin-top: 1rem;
}

.companyDistyourchannel .form-group{
  margin-top: 1rem;
}

@media screen and (max-width:767.96px) {
.facts-figures .card-body .row:nth-child(1) .row:nth-child(1){
  flex-wrap: nowrap;
  }
}


@media screen and (max-width:450px) {
.companyDist .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  height: 7rem;
  }

}



// CompanyProfileVendor

@media screen and (max-width:576px) {
  .companyVendor .row:nth-child(1) .brandCard{
    float: unset;
    width: fit-content; 
    }  
  .companyVendor .row:nth-child(1) .row:nth-child(1) .col-sm-5{
     text-align: -webkit-center;
    }
  }

  @media screen and (max-width:400.96px) {
    .companyVendorTrade .brand{
      padding: 16px 15px;
    }
    
  .companyVendorTrade .form-group{
    margin-top: 1rem;
  }

  .companyVendorTrade .card-body{
    padding: 0.5rem;
  }

  .companyVendorTrade .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 22px;
  }
  
  .companyProfileVendor .companyVenCertifications .MuiInputBase-root{
    height: 7rem;
  }

  .companyProfileVendor .MuiInputLabel-outlined {
    transform: translate(0px, 20px) scale(1);
  }
  .companyProfileVendor .companyVenCertifications .card-body{
    padding: 0.5rem;
  }

  .companyVendorPicture .card-body
  {
    padding: 0.5rem;
  }

  .companyVendorPicture .brand {
      padding: 15px 15px;
  }
  
  .companyProfileVendor .card-body{
    padding: 0.5rem;
  }

  }
  
  // .companyDistyourchannel .form-group{
  //   margin-top: 1rem;
  // }
@media screen and (max-width:600px){
  .companyprofileVendorBanner h5,
  .retailReseller .rereselbanner h5,
  .companyProfileDist .bannerImg h5{
    float: none !important;
  }

}
@media screen and (max-width:768px) and (min-width:576px) {
  .turnOver_Btn{
    flex-wrap: nowrap !important;
  }
}

.companyProfileVendor .scroll{
  width: 100%;
}

@media screen and (max-width:992px){
  .companyProfileVendor .officialDocuments h5,
  .retailReseller .officialDocuments h5{    
    float: none !important;
  }
}

.officialDocuments .scroll
{
  width: 100%;
}


// RetailReseller.js

@media screen and (max-width: 576px){
  
  .retailreseller .row:nth-child(1) .brandCard{
  float: unset;
  width: fit-content; 
  }  
  .retailreseller .row:nth-child(1) .row:nth-child(1) .col-sm-5{
   text-align: -webkit-center;
   padding-right: 0;
  }

}

@media screen and (max-width:400px) {
  .retailResellerTrade .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 22px;
  }
  .retailreseller .brand {
    padding: 15px 9px;
}
  .retailReseller .card-body,
  .companyProfileVendor .card-body{
    padding: 0.5rem;
  }
}


.retailResellerTrade .MuiFormControl-fullWidth {
    margin-top: 0.5rem;
  }

@media screen and (max-width:375px) {
  .retailresellerAwards .brand,
  .companyVendorAwards .brand
  {
    padding: 16px 10px;
  }
}

// ProfileView

@media screen and (max-width:991.96px){
  .profileviewlistingwrapper #leftFilter{
    margin-left: 0 !important;
  }
}
.profileviewlistingwrapper .list-tab .list-ven{
  width: fit-content !important;
}

@media  screen and (min-width:601px) {
  .profileviewlistingwrapper .right-search,
  .profileviewlistingwrapper .right-sort{
    position: absolute !important;
  }
  
}


// UserProfileForm

@media screen and (max-width:1100.96px) and (min-width:991.96px){
  .userProfileform .brandCard{
    margin: 0;
  }
  .userProfileform .yourpicture div:nth-child(2){
    padding-right: 0;
    padding-top: 0.5rem !important;
  }
}

@media screen and (max-width:991.96px){
  .userProfileform .yourpicture div:nth-child(2){
  padding-top: 0.5rem !important;
  }
}

@media screen and (max-width:350px){
  .userProfileform .card-body{
    padding: 0.5rem;
  }
}

// AdvancedSearch

@media screen and (min-width:320px) and (max-width:861.96px){
.advanceSearch .right-search {
     position: unset !important;
     margin-bottom: 0 !important;
}
}

@media screen and (max-width:601.96px){
  .advanceSearch .suggest-ven .list-ven{
    width:40% !important;
  }
}
.advanceSearch .list-ven a div{
  padding: 0;
  text-align: center;
}

// Productform

@media screen and (max-width:401px){
  .productForm .card-body{
    padding: 0.5rem;
  }
}

@media screen and (max-width:601px){
  .productForm .buytext22{
    height: 4rem !important;
  }
}

// PayCARD

@media screen and (max-width:426px){
.payCard .card-body{
  padding: 0;
} 
.payCard .card{
  padding: 0;
}
}

// FAVOURITES

@media screen and (max-width:500px) {
  .favouritesList .no-img{
    width:80%;
  }
}

// NOTIFICATION

@media  screen and (max-width:576px) {
  .notilogo{
    margin-left: 0px !important;
  }
}

// MYPRODUCTLIST

.myProductList .list-tab .list-main{
  width: 37% !important;
}

.myProductList .right-search1{
  padding-right: 0 !important;
}

.myProductList .right-search1 {
  width: 16rem;
}

.myProductList .sub-a a{
  text-align: center;
}

@media screen and (max-width:450px) {
  .myProductList .list-main h3{
    font-size: 10px;
  }
}


//Vendor DashBoard Tabs

.eventsnewTabs .tabs{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.eventsnewTabs .tabs-label{
  cursor: pointer;
  margin-right: 20px;
}

.eventsnewTabs .tabs-radio{
  display: none;
}

.eventsnewTabs .tabs-content{
  width: 100%;
  order: 1;
  // border-bottom: 3px solid black;
  line-height: 1.5;
  display: none;
  margin-bottom: 1rem;
  transform: 0.5s;
}

.eventsnewTabs .tabs-radio:checked+.tabs-label{
  border-bottom: 1px solid #2c9db7;
}

.eventsnewTabs .tabs-radio:checked+.tabs-label+.tabs-content{
  display: initial;
}
.activeBtn.btn-secondary {
  background-color:#f6f9fc; color: #32325d;
  border-bottom: none !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.collapsed {
  display: none;
}
}