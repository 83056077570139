// .class0{
//     display: inline;
// }

// .class1{
//     display: none;
// }
.buttonTitle{
    vertical-align: middle; 
    text-align: center;
}

.subscriptionplan_inactive{
        display: none;
    
}

button.class0{
    
    position: relative;
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
    background-color: #ED5A01 !important;
    box-shadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)';
    cursor: pointer;
    border-color: #ED5A01 !important;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 1.400rem 1.25rem; //0.625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

button.inactive{
    
    position: relative;
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
    background-color: #2c9db7  !important;
    box-shadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)';
    cursor: pointer;
    border-color: #2c9db7  !important;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 1.400rem 1.25rem; //0.625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.periodTitleTable{
    border-collapse: collapse;
    width: 65%;
    background-color: grey;
    float:right;
    td{
        width:50%;
        padding:15px;
    }
    .active{
        background-color: #ED5A01;
        color: white;
        font-size: 22px;
        text-align: center;
    }
}
.sub_submit{
    background-color: #ED5A01;
    border-color: #ED5A01;
    color: #fff;
    padding: 8px;
    font-size: 23px;
    border-radius: 10px;
}
.center-div{
    text-align: center;
}
/**************user subscription****************/
.confim_table{
    width: 100%;display: block;
    background-color: #ecf1f1;
    padding:20px;
}

.confim_table_title{
    width: 33%;display: inline-block; background-color: #2c9db7;
    padding: 15px;
    color: #fff;
    border: 1px solid #64868e;
    text-align: center;
}
.confim_table_title_row{
    width: 33%;display: inline-block;
    padding: 15px;
    border: 1px solid #64868e;
    text-align: center;
    background-color: #fff;
}
.bottom_row{
    text-align: center;
}
.okbut{
    padding: 15px;
    background-color: #2c9db7;
    border: 1px solid #2c9db7;
    border-radius: 2px;
    color: #fff;
}
.alterbut{
    padding: 15px;
    background-color: #ED5A01;
    border: 1px solid #ED5A01;
    border-radius: 2px;
    color: #fff;
}
.middle_row{
    width:49.4%; display: inline-block;
    
}
.col1{
    vertical-align: top;
    background-color: #fff;
    
}
.final_amount{
    text-align: center;
    font-weight: 600;
}
.coupanDiv{
    padding:30px;
}