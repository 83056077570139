/*-------------------------- Custom  Styles -------------------------------*/
// Signup
// Signup otp
// Signup Verify
// Dashboard CSS
//Subcription CSS
/* bgColors */
// body{
//     background-color: white !important;
// }
.curs-point {
  cursor: pointer !important;
}
.drk-blue {
  background-color: #2c9db7 !important;
}

.comming-soon {
  // background-image: url("/src/assets/img/brand/coming-soon-dashboard.png");
  height: 50%;
  width: 50%;
}
.back-but {
  margin-top: 2rem;
  float: left;
}
.navbar-nav .align-items-center.media{
  display: flex;
}
.ch-btn-blue {
  margin-top: 2rem;
  color: white;
}
.right-but {
  float: right;
}
.reset-but {
  text-align: center;
}
.register_package h2 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
  text-align: center;
  margin-top: 35px;
}

.register_package .row {
  @media screen and (max-width: 426px) {
    // margin:-10px !important;
    margin-top: 0px !important;
    justify-content: center !important;
  }
}
.package_euro {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.package_euro h1 {
  text-align: center;
}
.prof-001 {
  height: 12rem;
  padding: 2rem 0px !important;
}
.prof_001 {
  padding: 2rem 0px !important;
}
.prof-002 {
  height: 9rem;
  flex: 0 0 14.23333% !important;
  max-width: 14.23333% !important;
  // padding: 0px !important;
  text-align: center;
}
// .prof-002:hover .prof-span{
//   color: #3f51b5 !important;
// }
.prof-003:hover .prof-span {
  color: #3f51b5 !important;
}
.prof-row1 {
  margin: 0px !important;
}
.prof-img1 {
  width: 100%;
  margin-bottom: 0.5rem;
}
// .prof-span{           *****   this is 7 grid span response   ****
//   color: #999b9c;
//   font-weight: 600;
//   font-size: 0.683rem;
// }
.prof-span {
  color: #999b9c;
  font-weight: 600;
  font-size: 1rem;
  position: relative;
  left: 3rem;
  top: 0.22rem;
}
//   .img-ta {
//     width: 100%;
//     height: 7rem;
//     background: url("../../img/profile/vendor1-180x180.png") no-repeat;
//    background-size: 75px 5rem;
//     margin-bottom: -1rem;
// }
// .img-ta:hover {
//     background: url("../../img/profile/vendor-180x180.png") no-repeat;
//    background-size: 75px 5rem;
//     margin-bottom: -1rem;
// }
// .img-ta1 {
//   width: 100%;
//   height: 7rem;
//   background: url("../../img/profile/picto-profiles-distributor1.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta1:hover {
//   background: url("../../img/profile/picto-profiles-distributor.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta2 {
//   width: 100%;
//   height: 7rem;
//   background: url("../../img/profile/picto-profiles-retailers1.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta2:hover {
//   background: url("../../img/profile/picto-profiles-retailers.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta3 {
//   width: 100%;
//   height: 7rem;
//   background: url("../../img/profile/picto-profiles-busdev1.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta3:hover {
//   background: url("../../img/profile/picto-profiles-busdev.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta4 {
//   width: 100%;
//   height: 7rem;
//   background: url("../../img/profile/vendor1-180x180.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta4:hover {
//   background: url("../../img/profile/vendor-180x180.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta5 {
//   width: 100%;
//   height: 7rem;
//   background: url("../../img/profile/picto-profiles-distributor1.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta5:hover {
//   background: url("../../img/profile/picto-profiles-distributor.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta6 {
//   width: 100%;
//   height: 7rem;
//   background: url("../../img/profile/picto-profiles-busdev1.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
// .img-ta6:hover {
//   background: url("../../img/profile/picto-profiles-busdev.png") no-repeat;
//  background-size: 75px 5rem;
//   margin-bottom: -1rem;
// }
.img-v {
  width: 25px;
  height: 25px;
  background: url("../../img/profile/vendor1-180x180.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}

.img-o {
  width: 25px;
  height: 25px;
  background: url("../../img/profile/picto-profiles-busdev1.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}
.Keynote-wrapper{
  padding: 50px;
}
.Keynote{
float: left;
}
.Keynote h2{
  font-size: 50px;
}
.Keynote h3{
  font-size: 35px;
}
.Keynote h5{
  font-size: 30px;
}
.Keynote p{
  font-size: 20px;
}
.Keynote a{
  font-size: 20px;    margin: 10px 0 60px 0;
}
.Keynote img{
  margin: 30px 0;
}
.Keynote .Keynote.side{
  display: initial;padding: 0;padding-left: 40px;
}
.Keynote ul{
  padding: 0;
}
.Keynote .Keynote.side a{ 
  width: initial;
}
.Keynote .Keynote.side img{
width: initial;
}
.Keynote .Keynote.side h5{
width: initial;
}
.Keynote.side{
  display: flex;align-items: center;padding: 0 50px;width: 100%;
}
.Keynote.side a{
  margin: 20px 0;width: 15%;
}
.Keynote.side img{
width: 10%;
}
.Keynote.side h5{
  width: 70%;font-weight: 400;padding: 0 40px;font-size: 25px;
}
.Keynote.side a:nth-child(1){
  font-size: 25px;
  text-decoration: underline;
}
// .img-v:hover {
//   background: url("../../img/profile/vendor-180x180.png") no-repeat;
//   background-size: 25px 25px;
//   background-position: center;
//   margin-bottom: 1rem;
// }
.img-d {
  width: 25px;
  height: 25px;
  background: url("../../img/profile/picto-profiles-distributor1.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}
// .img-d:hover {
//   background: url("../../img/profile/picto-profiles-distributor.png") no-repeat;
//   background-size: 25px 25px;
//   background-position: center;
//   margin-bottom: 1rem;
// }
.img-r {
  width: 25px;
  height: 25px;
  background: url("../../img/profile/picto-profiles-retailers1.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}
.subs-button{
      margin: auto; background-color: #fff;width:35%;float: left;margin:10px 35px;padding: 10px 0;box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;transition: .3s all ease-in-out;cursor: pointer;margin-bottom: 20px;

}
.register_package{
  width: 80%;background: #f7f7f7;padding: 45px;padding-bottom: 0;
}
.selectprof .didy{
  position: relative;top: 4px;left: 6px;
}
.subs-button:hover{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.subs-button.active{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.eventsubs-tabs h2{
  font-size: 25px;margin-bottom: 20px;
}
.eventsubs-tabs{
  width:70%;margin: auto;text-align: center;
}
.eventsubs-tabs .prof-span{
  left:initial;
}
.eventsubs-tabs .img-v,.eventsubs-tabs .img-d{
  width: 80px;height: 80px;background-size: 80px 80px;display: block;margin: auto;
}

// .img-r:hover {
//   background: url("../../img/profile/picto-profiles-retailers.png") no-repeat;
//   background-size: 25px 25px;
//   background-position: center;
//   margin-bottom: 1rem;
// }
.img-re {
  width: 25px;
  height: 25px;
  background: url("../../img/profile/reseller.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}
// .img-re:hover {
//   background: url("../../img/profile/reseller-hov.png") no-repeat;
//   background-size: 25px 25px;
//   background-position: center;
//   margin-bottom: 1rem;
// }
.prof-003 {
  text-align: center;
}
.CHimage {
  background-color: white;
  top: 0;
  position: absolute;
  width: 100%;
  height: 70px;
  text-align: center;
}
.img-v-ac {
  background: url("../../img/profile/vendor-180x180.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}
.img-dac {
  background: url("../../img/profile/picto-profiles-distributor.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}
.img-rac {
  background: url("../../img/profile/picto-profiles-retailers.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}

.img-res {
  background: url("../../img/profile/reseller-hov.png") no-repeat;
  background-size: 25px 25px;
  background-position: center;
  margin-bottom: 1rem;
}
.activeac {
  color: #3f51b5 !important;
}

// .mobile_verify{
//   .Fullotp{
//     width: 60% !important;
//   }

// label{
//   text-align: center !important;
//   margin-left: 17.5rem;
// }
//   button{
//     color: rosybrown;
//     background-color: salmon !important;
//   }
//   input{
//    width: 700px;
//    margin-top: 1rem;
//   }
// }
// .otp{
//   button{
//     display: inline-block;
//     font-weight: 600;
//     color:white;
//     text-align: center;
//     vertical-align: middle;
//     cursor: pointer;
//     -webkit-user-select: none;
//     user-select: none;
//     background-color: transparent;
//     border: 1px solid transparent;
//     padding: 0.625rem 1.25rem;
//     font-size: 0.875rem;
//     line-height: 1.5;
//     border-radius: 0.25rem;
//     background-color: #5e72e4;
//     margin-top: 1rem!important;
//     margin-left: 1rem !important;

//   }
// input{
//   width: 100px !important;
//   margin-left: 00.625rem;

// }
// span{
//   margin-top: 1.5rem !important;
//   float: right !important;
//   margin-left: 36rem;
// }
// }
// // .MuiButtonBase-root{
// //   background-color: salmon !important;
// // }
// .MuiTab-textColorPrimary.Mui-selected {
//   color:black !important;
//   background-color: #5e72e4;
// }

// .MuiTabs-root {
//   display: flex;
//   overflow: hidden;
//   min-height: 64px;

// }

// .MuiTab-labelIcon {

//   border-radius: 50px !important;

// }
// .MuiPaper-elevation4 {
//   box-shadow: none !important;
//   color: white !important;
//   /* box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12); */
// }
// .MuiPaper-root {
//   color: rgba(0, 0, 0, 0.87);
//   transition:none;

// }

.tempevent-modal{
  max-width: 1200px; 
}

.tempevent-modal h2{
  text-align: center;
}
.tempevent-modal h4{
  margin-bottom: 15px !important;font-weight: 600;font-size: 16px;margin-top: 15px !important;
}
.temp-cont{
  position: absolute;bottom: 290px;left: 55px;width: 140px;height: 140px;
}
.temp-cont p{
  color: #fff;position: relative;top: 10px;left: -5px;
}
.temp-edit svg{
  font-size: 18px;
}
.temp-edit{
  position: absolute;top: 137px;right: 0;z-index: 2; left:464px;color:#fff;
}
.brandCard .MuiButtonBase-root{
  z-index: 2 !important;
}
.tempevent-right, .tempevent-left{
  text-align: center;
}

.share-linkedin{
  background-color: #03a9f4 !important;color: #fff;padding: 10px;margin: auto;margin-top: 20px;
}
.tempeft-image{
  position: relative;width: 100%;height: 100%;text-align: left;
}

.temp-para{
 // position: absolute;bottom: 160px;right: 225px;color: #fff;text-align: left;
  position: absolute;
  bottom: 150px;
  right: 168px;
  color: #fff;
  text-align: left;
  //border: 1px solid #fff;
  min-height: 42%;
  min-width: 27%;
  max-height: 42%;
  max-width: 27%;
}
.tempeft-image img{
  max-width: 100%;max-height: 100%;
}
.temp-logo{
  z-index: 2;position: absolute;width: 140px;height: 140px;cursor: pointer;left:0;right: 0;top: 0;bottom: 0;margin: auto;
}
.shareicon{
  background-color: #03a9f4;color: #fff;padding: 10px 8px;border-radius: 4px;font-size: .875rem;font-weight: 600;top: 1px;
}
.shareicon svg{
  font-size: 16px;position: relative;top: -2px;
}
.down-btn{
  background-color: #4CAF50 !important;padding: 10px 10px;
}
.samp-down-btn{
  padding: 15.2px 30px !important;position: relative;top: -11.2px;left: 8px;
}
.listing-share{
  // padding: 11px 18px;display: inline-block;position: relative;left: -4px;
}
.sharemodal.modal-dialog{
  max-width: 85% !important;
}
.sharemodal.modal-dialog .linkedin{
  max-width: 58px;max-height: 58px;border-radius: 3px;left: 15px;cursor: pointer;
}
.sharemodal.modal-dialog .temp-cont{
  bottom: 134px;left: 53px;
}
.sharemodal.modal-dialog .modal-body{
  background-color: #eeeff1;padding: 0;
}
.sharemodal-left{
  text-align: center;padding: 15px;
}
.sharemodal.modal-dialog .temp-para{
  font-weight: 600 !important;
}
.sharemodal-left .btn{
  background-color: #03a9f4;padding: 15px 30px;font-size: 18px;color: #fff;border: none;cursor: pointer;
}
.sharemodal-left h2{
  font-size: 30px;font-weight: bold;
}
.sharemodal-left p{
  font-size: 25px;
}

#popup-share-Modal{
  max-width: 50% !important;
  margin-top: 10%;
}

.channel-logo{
  max-width: 50%;max-height: 50%;margin-top: 20px;
}
.down-exhibitor{
  margin-top: 15px;display: block;text-align: center;    left: 20px;
  position: relative;
}
.sharetemp-close{
  background-color: #fff;width: 30px;height: 30px;border-radius: 30px;color: #000;position: absolute;top: 0;line-height: 30px;font-size: 20px;text-align: center;
  cursor: pointer;
top: -10px;right: -5px;font-weight: bold;
}
.temp-logo img {
  position: absolute;
  max-width: 83%;
  max-height: 83%;
  left: 515px;
  top: 100px;
  border: 2px solid orange;  
}

#temp-log0-userImg{
  border-radius: 50%;  
}
.otp {
  margin-left: 6.9rem !important;
}
.MuiAutocomplete-popperDisablePortal{
  position: relative !important;
}
// button{

//     color: #fff;
//     background-color: #5e72e4;
//     border-color: #5e72e4;
//     box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
//     margin-top: 1rem !important;
//     margin-left: 3.9rem !important;
// }
.telecard {
  .react-tel-input {
    width: 100% !important;
    @media only screen and (max-width: 600px) {
      width: 262px !important;
    }
  }
  .form-control {
    width: 100% !important;
    font-size: 18px;
    @media only screen and (max-width: 600px) {
      width: 262px !important;
    }
  }
  .react-tel-input .flag-dropdown.invalid-number {
    border-color: #c4c4c4;
  }
  .react-tel-input .form-control.invalid-number {
    border: 1px solid #c4c4c4;
    background-color: #ffffff;
    border-left-color: #c4c4c4;
  }
  .react-tel-input .form-control.invalid-number:focus {
    border: 1px solid #c4c4c4;
    background-color: #ffffff;
    border-left-color: #c4c4c4;
  }
  .react-tel-input .flag-dropdown.invalid-number {
    border-color: #c4c4c4;
  }
}
.otpphone {
  .react-tel-input {
    width: 300px !important;
    @media only screen and (max-width: 600px) {
      width: 262px !important;
    }
  }
  .otp_get {
    width: 100%;
    text-align: -webkit-center;
    // display: none;
  }
  .form-control {
    width: 300px !important;
    @media only screen and (max-width: 600px) {
      width: 262px !important;
    }
  }
}
.otp-verify {
  width: 100%;
  padding-left: 13rem;
  padding-right: 13rem;
  @media only screen and (max-width: 600px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.verify-box {
  border: 1px solid #cacaca;
  height: auto;
  position: relative;
  padding: 2rem;
  border-radius: 10px;
  background-color: #ffff;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.15) !important;
  div {
    display: block !important;
    text-align: center;
    padding: 1rem 0rem;

    button {
      border-radius: 7px !important;
      height: 2.2rem !important;
      border-color: none !important;
      border-color: #cacaca !important;
      // background-color: #e87d2e !important;
      color: #ffff !important;
    }
  }
  input {
    border-bottom: 1px solid #cacaca !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    background-color: #ffff !important;
    margin-left: 5px !important;
    margin-right: 10px !important;
    @media only screen and (max-width: 600px) {
      width: 21px !important;
    }
  }
  span {
    padding-right: 1rem !important;
    font-size: 14px !important;
    margin-top: 1rem !important;
    color: #000;
  }
  p {
    font-size: 14px !important;
    margin-top: 1rem !important;
    color: #000;
  }
  h3 {
    color: #000;
  }
}
.head-logo {
  width: 100%;
  height: 4rem;
  text-align: center;
  // margin-bottom: 1rem;
  margin-top: 1rem;
  @media only screen and (device-width: 1360px) {
    height: 3rem !important;
  }
  @media only screen and (device-width: 1366px) {
    height: 3rem !important;
  }
  img {
    height: 4rem;
    @media only screen and (device-width: 1360px) {
      height: 3rem !important;
    }
    @media only screen and (device-width: 1366px) {
      height: 3rem !important;
    }
  }
}
.head-logo1 {
  width: 100%;

  text-align: start;
  img {
    // height: 9rem;
    margin-top: 4rem;
    margin-bottom: 1rem;
    position: absolute;
    @media only screen and (max-width: 600px) {
      height: 4rem !important;
    }
  }
}
.meeting-table .css-1ex1afd-MuiTableCell-root{
  font-family: SFMono-Regular, Menlo, Monaco, Consolas,  "Liberation Mono", "Courier New", monospace;font-size:15px !important;
}
.meeting-table{
  width:80% !important;margin:auto !important;font-family: SFMono-Regular, Menlo, Monaco, Consolas,  "Liberation Mono", "Courier New", monospace !important;box-shadow: initial !important;
}
.meeting-table .css-177gid-MuiTableCell-root{
  font-family: SFMono-Regular, Menlo, Monaco, Consolas,  "Liberation Mono", "Courier New", monospace;font-size:15px !important;

}
.modal-dialog.modal-dialog-centered.companypic-zoom{
  max-width: 800px !important;
}
.companypic-zoom .imgprev{
width: 750px !important;height: 550px !important;position: relative;margin-left: 25px;
}
.companypic-zoom .imgprev img{
  position: absolute;max-width: 100%;max-height: 100%;top: 0;left: 0;right:0;bottom: 0;margin: auto;
}
.meeting-table .btn{
  font-size: 14px !important;
}
.mobi-res {
  @media only screen and (max-width: 600px) {
    text-align: center !important;
  }
}
.mobi-res1 {
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
  span {
    width: 100% !important;
  }
}
.mobi-res2 {
  @media only screen and (max-width: 600px) {
    text-align: center !important;
    width: 100% !important;
  }
}
.mobi-res3 {
  @media only screen and (max-width: 600px) {
    margin-left: 0px !important;
  }
  @media only screen and (device-width: 320px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.pad-01 {
  @media only screen and (max-width: 600px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.mobi-res4 {
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
}
.mobi-res5 {
  @media only screen and (max-width: 600px) {
    text-align: center !important;
    i {
      float: none !important;
    }
  }
}
.ext-pad {
  padding-left: 46px !important;
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
}
.complog {
  left: 0;
}
.sideicon-color {
  color: rgb(44, 157, 183) !important;
}
p {
  font-weight: 400 !important;
}
.Profad {
  background-color: #f7fafc;
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
}

// .col-lg-6

// input{
//   background-color: #ffff;
//   border: 0;
//     transition: box-shadow .15s ease;
//     box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);
// }
.wek-cent {
  text-align: -webkit-center !important;
}

.Outercard {
  background-color: #f1f1f1;
  padding: 3rem;
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
}
//  .address-tab{
//    height: 14rem !important;
// }

// globalstyles FormTextArea,FormSelect,FormFileInput,FormInput,FormButton,FormMultiSelect,FormCheckBox,
// FormAutocomplete,FormRadio,PhoneInput1,PhoneInput2,OTPInput,OtpInput
// .FormTextArea{
//   background-color: #ffff !important;
// }
// .FormSelect{
//   background-color: #ffff !important;
// }
// .FormSelect{
//   background-color: #ffff !important;
// }
// .FormFileInput{
//   background-color: #ffff !important;
// }
// .FormInput
// input{
//   background-color: #ffff !important;
// }
// .FormButton{
//   background-color: #ffff !important;
// }
// .FormMultiSelect{

//   background-color: #ffff !important;
// }
// .FormAutocomplete{
//   background-color: #ffff !important;
// }
// .PhoneInput1{
//   background-color: #ffff !important;
// }
// .PhoneInput2{
//   background-color: #ffff !important;
// }
// .OTPInput{
//   background-color: #ffff !important;
// }
// .OtpInput{
//   background-color: #ffff !important;
// }
.MuiInputBase-input {
  background-color: #ffff !important;
}
.MuiAutocomplete-inputRoot {
  background-color: #ffff !important;
}
.MuiInputBase-root:hover {
  border: none !important;
  outline: none !important;

}
.ListContainer{
  padding: 15px;margin: 15px 0;
}
.list-image{
  position: relative;width: 180px;height: 180px;margin: auto;
}
.ListContainer p{
  float: left;
    color: #777;width: 100%;font-size: 14px;
}
.ListContainer h3{
  float: left;font-size: 15px;width: 100%;
}
.list-image img{
  position: absolute;max-width: 100%;max-height: 100%;top: 0;left: 0;right: 0;bottom: 0;margin: auto;
}
//channelDeatailview
.img-001 {
  text-align: center;
}
.comp-head {
  background-color: #f3f3f3;
  h2 {
    text-align: left;
    position: absolute;
    padding: 8px;
    margin: 0px;
  }
  .myprf-btn {
    border: 3px solid #6feebe;
    width: 20%;
    padding: 7px 4px 0px;
    border-radius: 7px;
    // position: relative;
    /* right: 0px; */
    float: right;
    top: 2rem;
  }
  .stable {
    max-width: 1366px !important;
  }
  .comp-row {
    padding-top: 4rem;
  }
}
.Prf-card {
  padding: 12px 3px !important;
}

.info-block {
  height: 14rem;
  text-align: center;
  .avatar-xl {
    width: 150px !important;
    height: 150px !important;
  }
  h3 {
    padding-top: 1rem;
  }
}

//SideBar
.filter-hide-class {
  right: -70px;
}
.filter-show-class {
  right: 0px;
}
.filter-hide-width {
  width: 0px !important;
}
.nav-bar {
  width: 62px !important;
}
.line-hr {
  width: 70px;
  padding-right: 1rem;
}
.jc-01 {
  justify-content: center;
}
//login
.login-btn {
  button {
    margin-bottom: 1rem !important;
  }
}
.login-hr {
  margin-top: 1rem !important;
  margin-bottom: 0.8rem !important;
}
.signup-col {
  text-align: center;
  font-size: 0.875rem;
}
.agree {
  text-align: center !important;
  height: 4rem;
}
.forgot {
  text-decoration-line: underline;
  float: right;
  top: 0.5rem;
  position: relative;
}
.agree1 {
  text-align: end !important;
}
.chck {
  padding-top: 0.3rem;
}
.signup-header-bg {
  background: linear-gradient(87deg, #11cdef 0, #172b4dcf 100%) !important;
}
.signup-header-bg1 {
  background: url(../../img/common/eventsignup.jpg);
  background-size: cover;
}

.signup-header-emea {
  background: url(../../img/common/emeasignup.png);
  background-size: cover;
}

.signup-header-buyer {
  background: url(../../img/common/buyersignup.png);
  background-size: cover;
}

.card {
  box-shadow: none !important;
}

.shadow {
  border: 0 !important;
  // margin-top: 2rem !important;
}
.text-marg {
  margin-top: 2rem !important;
}
.dash {
  margin-top: 1rem !important;
  font-size: 18px !important;
}
.detail-dashboard {
  padding-top: 5rem;
  .detail-row {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
.pad-less {
  padding: 0px !important;
}
.pad-les {
  padding-left: 0px !important;
}
.top {
  margin-top: 0.5rem !important;
}
.country1 {
  margin-top: 0.5rem;
  border: 2px solid #6feebe;
  width: 46%;
  text-align: left;
  padding: 5px;
  border-radius: 5px;
  h3 {
    margin: 0px !important;
  }
}
#lstnews .media-comment{
  width: 90%;position: relative;
    margin-left: 20px;
}
.buttons-space button{
  margin-bottom: 10px;
}
.rate-group {
  text-align: center;
  padding: 47px;
  .f-size {
    font-size: 22px;
  }
  span {
    margin-right: 10px;
  }
}
.rate-star {
  padding: 31px;
  .align {
    float: left;
    margin-top: 5px;
  }
  .type1 {
    border: 1px solid #6feebe;
    text-align: center;
    padding: 5px 15px;
    border-radius: 6px;
    h3 {
      margin: 0px !important;
    }
  }
  .type2 {
    border: 1px solid #6feebe;
    text-align: center;
    padding: 5px 15px;
    border-radius: 6px;
    h3 {
      margin: 0px !important;
    }
  }
}
.card-height {
  height: 14.4rem;
}

//------------------------------ Dashboard Css----------------------------------//

#dashboard {
  .timeline-one-side:before {
    left: -1rem !important;
  }
  .timeline-one-side .timeline-step {
    left: -1rem !important;
    top: 1.3rem !important;
  }
  .avatar {
    border: 1px solid #e9ecef;
    background-color: white;
  }
}

.TP {
  margin-top: 2rem !important;
}
.ram {
  width: 4rem !important;
  padding-top: 0.5rem !important;
  padding-top: 1rem;
}

//Profile Brand Card

.brand {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 16px 30px;
}
.scroll {
  max-height: 630px;
  overflow-x: hidden;
  width: 100%;
}
.brandCard {
  float: left;
  margin: 10px;
  position: relative;
  text-align: center;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  background-color: #fff;
  display: -ms-grid;
  display: grid;
  margin-left: 0;
}
.imgCont {
  object-fit: contain;
}
.res-sm {
  @media only screen and (max-width: 600px) {
    width: 50% !important;
  }
}
.xbtn {
  position: absolute;
  background: transparent;
  top: -16px;
  right: -25px;
  border: none;
}

.imgContiner {
  cursor: pointer;
  width: 130px;
  height: 130px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  #pdfPreview {
    height: 120px !important;
    .react-pdf__Page__svg {
      height: 120px !important;
    }
  }
  video {
    width: 100%;
    height: 100%;
  }
  i {
    position: absolute;
    left: 36%;
    top: 40%;
    font-size: 35px;
  }
}
.shadecard .slick-next {
  right: -15px !important;
}
.shadecard .slick-prev {
  left: -15px !important;
}
.shadecard .slick-prev:before,
.shadecard .slick-next:before {
  font-size: 25px;
}
.imgContiner1 {
  position: relative;
  width: 100%;
  height: 350px;
}
.imgContiner1 img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.picvideo-wrap {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 20px 0;
}
.news-img{
  position: relative;width: 400px;height: 300px;margin: auto;overflow: hidden;
}
.news-img img{
  position: absolute;max-width: 100%;max-height: 100%;top: 0;left: 0;right: 0;bottom: 0;margin: auto;
}
.pic-img {
  margin-top: 20px;
  position: relative;
  float: left;
  width: 100%;
  height: 400px;
}
.pic-img img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  margin: auto;
}
.award-logo {
  position: absolute;
  width: 75px;
  height: 75px;
  right: 10px;
}
.res-close{
  display: none;
}
.award-logo img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}
.imgContiner img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: initial !important;
  height: initial !important;
}
.exhibitor-guide{
  position: relative;
}
.exhibitor-guide a{
  position: absolute;
    z-index: 1;
    color: #fff;
    bottom: 10%;
    margin: auto;
    left: 35px;
    right: 0;
    text-align: center;
    text-decoration: underline;
}
.exhibitor-guide img{
  max-width: 100%;max-height: 100%;
  position: relative;
  margin-top: 47px;
  left: 20px;
}
.Brandimg {
  width: 100%;
  background-color: #fff;
  height: 100%;
  cursor: pointer;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  transform: none;
  -webkit-transform: none;
  top: initial;
  margin: 0 auto;
  object-fit: contain;
}
.BrandName {
  font-size: 14px;
  margin: 0 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4c4c4c;
  padding-top: 10px;
  text-align: center;
  max-width: 106px;
  padding-left: 10px;
}
.BrandCloseSvg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  border-radius: 50%;
  background: rgb(248, 248, 248);
  width: 22px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
  height: 22px;
  margin: 0 5px 0 -6px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}
.ProfileImage {
  text-align: center;
  // background: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 50%;
  left: 40%;
  z-index: 999;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.SellingRegions {
  .MuiInputLabel-formControl {
    top: -10px;
    font-size: small;
  }
  input {
    padding: 5px;
    height: 2px;
  }
}

#profile_pic {
  display: none;
}
.preview-images-zone {
  position: absolute;
  z-index: 99;
  //  padding: 10px;
  margin: 20px;
  margin-top: 35px;
}
#dropzone-multiple {
  overflow-y: scroll;
  border: 1px dashed #dee2e6;
  .dz-message {
    background-color: transparent !important;
    height: 230px;
    border: none !important;
    button {
      position: relative;
      top: -30px;
    }
  }
}
.preview-image {
  height: 150px;
  width: 120px;
  position: relative;
  float: left;
  margin-bottom: 30px;
  border: 1px solid #dfebf3;
  padding: 8px;
  margin-right: 12px;
}
.image-Preview {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  img {
    max-width: 102px;
    position: relative;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    height: 100%;
    //left: 50%;
  }
}
#pdfPreview {
  border: 1px solid #dfebf3;
  height: 190px;
  /* width: 109px; */
  padding: 5px;
  // margin-bottom: 20px;
  @media only screen and (device-width: 2560px) {
    height: 260px;
  }
  .react-pdf__Document {
    height: 100% !important;
    overflow: hidden !important;
  }
  .react-pdf__Page__svg {
    border-radius: 8px;
    //max-width: 120px !important;
    width: 100% !important;
    height: 180px !important;
    @media only screen and (device-width: 2560px) {
      height: 210px !important;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  canvas {
    border-radius: 8px;
    //max-width: 120px !important;
    width: 100% !important;
    height: 180px !important;
  }
}
#dropzone_pdf {
  .MuiGrid-spacing-xs-8 {
    margin: 0 !important;
  }
  .MuiGrid-grid-xs-4 {
    padding: 10px !important;
    width: 120px !important;
    flex-basis: 0 !important;
    margin-right: 20px;
  }
  .annotationLayer {
    width: 0px !important;
    height: 0px !important;
  }
  .react-pdf__Page__textContent {
    width: 0px !important;
    height: 0px !important;
  }
}
#media_lib {
  .BrandName {
    border: 1px solid #dfebf3;
  }
  .annotationLayer {
    display: none !important;
  }
  .react-pdf__Page__textContent {
    display: none !important;
  }
  #pdfPreview {
    height: 133px !important;
    margin-top: 10px;
    canvas {
      height: 133px !important;
    }
  }
}
.MuiInput-underline::before {
  border-bottom: none !important;
}

#popupedit {
  .MuiInput-underline::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  }
}

.MuiInput-underline::after {
  border-bottom: none !important;
}
.makeStyles-margin-3 {
  margin: auto !important;
  margin-bottom: 15px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px;
  width: 100%;
  padding: 0px 15px !important;
  background-color: #ffff;
}

.awd-date {
  .MuiFormControl-root {
    width: 100% !important;
    padding: 0px 15px !important;
  }
}
.qtytext {
  max-width: 100% !important;
  width: 100% !important;
}
.pre-rate {
  font-size: 16px;
  margin: 0px;
  color: #ffff;
  span {
    font-size: 12px;
  }
}
.pre-tab {
  tr {
    height: 5rem;
  }
  td {
    border: none !important;
  }
  .td-align {
    text-align: -webkit-center;
  }
  .descripe {
    width: 32%;
  }
}
.des-001 {
  background-color: #f8f9f4;
  border-radius: 20px;
  padding: 20px 20px 0px;
}
.des-002 {
  padding: 20px 20px 0px;
  span {
    font-size: 13px;
  }
}
.gt-clr {
  background-image: linear-gradient(#ffff, #f8f9f4, #ffff);
}
// --------------------------------------------- subscription style -------------------------------------------
.pre-plan {
  padding: 25px;
  border-radius: 20px;
  @media only screen and (min-width: 1900px) {
    padding: 0px !important;
  }
}
.pre-001 {
  border: 2px dashed #aaa;
  padding: 20px;
  border-radius: 10px;
  @media only screen and (min-width: 1900px) {
    padding: 15px !important;
  }
}
.emp-tr {
  width: 23.7rem !important;
}
.emp-tr1 {
  width: 32% !important;
  h1 {
    font-size: 2rem !important;
  }
  hr {
    border-bottom: 2px solid #2c9db7;
    width: 22%;
    float: left;
    margin: 0px;
  }
}
.tab-1 {
  tr:nth-child(5) td:nth-child(1),
  tr:nth-child(6) td:nth-child(1),
  tr:nth-child(7) td:nth-child(1),
  tr:nth-child(8) td:nth-child(1),
  tr:nth-child(9) td:nth-child(1),
  tr:nth-child(10) td:nth-child(1),
  tr:nth-child(11) td:nth-child(1),
  tr:nth-child(12) td:nth-child(1),
  tr:nth-child(13) td:nth-child(1),
  tr:nth-child(14) td:nth-child(1) {
    background: #f8f9f4;
  }
  tr:nth-child(5) td:nth-child(3),
  tr:nth-child(6) td:nth-child(3),
  tr:nth-child(7) td:nth-child(3),
  tr:nth-child(8) td:nth-child(3),
  tr:nth-child(9) td:nth-child(3),
  tr:nth-child(10) td:nth-child(3),
  tr:nth-child(11) td:nth-child(3),
  tr:nth-child(12) td:nth-child(3),
  tr:nth-child(13) td:nth-child(3),
  tr:nth-child(14) td:nth-child(3) {
    background-image: linear-gradient(#ffff, #f8f9f4, #ffff);
  }
  tr:nth-child(5) td:nth-child(5),
  tr:nth-child(6) td:nth-child(5),
  tr:nth-child(7) td:nth-child(5),
  tr:nth-child(8) td:nth-child(5),
  tr:nth-child(9) td:nth-child(5),
  tr:nth-child(10) td:nth-child(5),
  tr:nth-child(11) td:nth-child(5),
  tr:nth-child(12) td:nth-child(5),
  tr:nth-child(13) td:nth-child(5),
  tr:nth-child(14) td:nth-child(5) {
    background-image: linear-gradient(#ffff, #f8f9f4, #ffff);
  }
}
tr:nth-child(5) td:nth-child(1) {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
tr:nth-child(14) td:nth-child(1) {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.unt-hr {
  text-align: -webkit-center;
  hr {
    border-bottom: 2px solid #2c9db7;
    width: 10%;
    margin: 0px;
  }
}

.exportWrapper {
  padding: 1.25rem;
  padding-bottom: 1.25rem;
  border: 1px solid #e6ecf1;
  border-radius: 0.25rem;
  background-color: #f5f7f9;
}
.video-recorder {
  text-align: center;
  width: 100%;
}
.modalSpinner {
  text-align: center;
  top: 50%;
  left: 40%;
  z-index: 999;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
.bt-pri {
  background-color: #aaaaaa !important;
  border-color: #aaaaaa !important;
}
.bt-pri1 {
  background-color: #2c9db7 !important;
  border-color: #2c9db7 !important;
}
.crow {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #ffff;
}
//  ----------------------------------------------------------- initial page style --------------------------------------------------------
.reg1 {
  width: 25rem !important;
  @media only screen and (max-width: 1600px) {
    width: 20.96rem !important;
  }
  @media only screen and (max-width: 600px) {
    width: 58% !important;
  }
}
.reg2 {
  width: 25.96rem !important;
  @media only screen and (max-width: 1600px) {
    width: 20.96rem !important;
  }
  @media only screen and (max-width: 600px) {
    width: 58% !important;
  }
}
.reg {
  .input-group-prepend {
    height: 3.5rem !important;
    overflow: hidden !important;
  }
  span {
    width: 12rem;
    @media only screen and (max-width: 1600px) {
      width: 4rem !important;
    }
    @media only screen and (max-width: 600px) {
      width: 7rem !important;
    }
  }
}

.vat1 {
  height: 56px !important;
}

.dashed-divider {
  border: 1px dashed #aaa;
}
#exportVen {
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #2c9db7 !important;
  }
}
.lib-img {
  max-width: 100%;
  height: 100%;
}
//  --------------------------------------- media library ------------------------------------------
.medd-view {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #d0d0d0;
  .fa-eye {
    color: #2c9db7;
  }
  .fa-eye:hover {
    font-size: 20px;
    cursor: pointer;
  }
  .fa-trash-alt {
    color: red;
  }
  .fa-trash-alt:hover {
    font-size: 20px;
    cursor: pointer;
  }
}

.bill {
  .fa-eye {
    color: #2c9db7;
  }
  .fa-eye:hover {
    font-size: 20px;
    cursor: pointer;
  }
  .fa-trash-alt {
    color: red;
  }
  .fa-trash-alt:hover {
    font-size: 20px;
    cursor: pointer;
  }
}
// .editeye:hover{
//   background-color: rosybrown;
//   border-radius: 3px;
// }
// .deleteicon:hover{
//    background-color: rosybrown;
//   border-radius: 3px;}
// // -------------------------------------------------------------------------------------------------

.modcrop {
  width: 30% !important;
  height: 30% !important;
}
//------------------------spinner----------------
.ant-spin {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: "";
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1890ff;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation-name: antRotateRtl;
  animation-name: antRotateRtl;
}
@-webkit-keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
// .tag-switch{
//   .custom-toggle-slider:before {
//   left: 11.5px !important;
//   }
//   }
.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #2dce89;
  transform: translateX(55px) !important;
}
.custom-toggle-success input:checked + .custom-toggle-slider:after {
  color: #2dce89 !important;
  margin-left: 10px !important;
}
.no-records {
  height: 25rem;
  width: 100%;
  padding: 15px;
  text-align: -webkit-center;
  @media only screen and (max-width: 800px) {
    height: auto !important;
  }
}
.no-records p{
  margin-top: 20px;
}
.no-records .no-img {
  width: 23% !important;
  // height: 11rem;
}
.no-img {
  width: 23% !important;
  // height: 11rem;
}
.imgwish {
  position: absolute !important;
}
.eventsnewTabs .tabs-radio:checked + .tabs-label {
  border-bottom: 2px solid #2c9db8 !important;
}
.eventsnewTabs .tabs-label {
  margin-bottom: 1.5rem;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.dashslide-cont {
  float: left;
  width: 100%;
}
.dashslide-left {
  float: left;
  width: 30%;
}
.dashslide-right {
  float: left;
  width: 70%;
  padding-right: 10px !important;
}
.detailvideo-wrap{
  width:500px;height:400px;margin:auto;border:1px solid #b8b8b8;position:relative;margin-bottom: 20px;
}
.detailvideo-wrap #myIntroVideo{
  border:initial !important;
}
// --------------------------------------------------- Whish list ----------------------------------------
.img-wish {
  width: 4%;
  // border: 1px solid #726f6fa6;
  border-radius: 4px;
  overflow: hidden;
}
.pad-wish {
  padding: 1.8rem 1rem !important;
  @media only screen and (max-width: 600px) {
    padding: 2.5rem 1rem !important;
  }
}
.pad-wish1 {
  padding-top: 3.6rem;
  @media only screen and (max-width: 600px) {
    padding-top: 0.6rem !important;
  }
}
.mt4 {
  margin-top: 2.3rem !important;
  @media only screen and (max-width: 600px) {
    margin-top: 0.3rem !important;
    margin-bottom: 2rem !important;
  }
}
.res-wish {
  @media only screen and (max-width: 600px) {
    margin-top: 0rem !important;
  }
}
// .linewish{
//   // border-right: 2px solid #e9ecef !important;
// }
.imgprev {
  img {
    position: absolute;max-width: 100%;max-height: 100%;top: 0;left: 0;bottom: 0;right: 0;margin: auto;
    //  padding: 2rem 2rem 2rem 2rem;
  }
  margin-left: 3rem;
  width: 400px !important;
  height: 350px !important;
  position: relative;
}

.pdfContiner {
  cursor: pointer;
  width: 132px;
  height: 132px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  #pdfPreview {
    height: 120px !important;
    .react-pdf__Page__svg {
      height: 120px !important;
    }
  }
  .pdf-view {
    width: 100%;
    height: 100%;
  }
}

.bttnn {
  // float: right;
  // width: 50%;
  border-radius: 16px;
}
.detailimgpdf {
  width: 100px;
  height: 50px;
}
.reem {
  padding: 0.5rem;
}

.Tren {
  border-bottom: 2px dashed #4e4e4c13;
}
.fp-containergear {
  z-index: 99999999;
  position: fixed;
  width: 100%;
  height: 100%;
  user-select: none;
  top: 0;
  left: 0;
  background: white;
}
.fp-containergear img {
  top: 40%;
  left: 45%;
  z-index: 999999;
  position: absolute;
}
.firstsection {
  position: absolute;
  top: 10%;
}
.sSecondection {
  position: absolute;
  top: 70%;
  left: 45%;
  z-index: 99999999;
  // p{

  // }
}
.fp-container {
  z-index: 99999999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;
}
.fp-container .fp-loader {
  top: 48%;
  left: 48%;
  z-index: 999999;
  position: absolute;
}
.doc-div {
  padding: 0px 60px;
}

///---------------------------notify ----------------

.NavIcons {
  position: relative;
  cursor: pointer;
  span {
    padding: 0.25rem 0.275rem;
    position: absolute;
    top: -7px;
    width: 1rem;
    height: 1rem;
  }
}
// <-----------------------------------------ConnectPOPUP----------------------------------------->
.chkcmpnyprofile {
  width: 50%;
  text-align: center;
  margin-left: 183px;
}
.connectptag {
  padding: 2rem 0rem 0rem 2rem;
}
// <------------------------------------postbuyingneed--------------------------------------------------->
.postneed {
  background-position: center;
  background-size: cover;
  background-image: url(https://cdn.pixabay.com/photo/2015/01/08/18/26/write-593333_960_720.jpg);
  display: inline-block;
  // width: 100%;
  // height:100%;
}
.btnpost {
  margin-top: 18rem;
}
.need {
  padding: 1rem;
}
.img-pbn {
  width: 100%;
  height: 100%;
}
// <----------------------------------------channeldismiss/connect------------------------->
.formtxtarea {
  width: 600px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  margin-top: 1rem !important;
  //   box-shadow: 0px 3px 14px -2px rgba(0,0,0,0.75) !important;
  //   -webkit-box-shadow: 0px 3px 14px -2px rgba(0,0,0,0.75);
  // -moz-box-shadow: 0px 3px 14px -2px rgba(0,0,0,0.75);
}
.formtxtarea:focus {
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}
.formtxtarea1 {
  width: 28rem !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  margin-top: 1rem !important;
  //   box-shadow: 0px 3px 14px -2px rgba(0,0,0,0.75) !important;
  //   -webkit-box-shadow: 0px 3px 14px -2px rgba(0,0,0,0.75);
  // -moz-box-shadow: 0px 3px 14px -2px rgba(0,0,0,0.75);
}
.formtxtarea1:focus {
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}
.fav-cl1 {
  position: absolute;
  background: #f8f8f8;
  color: #2c9db7;
  top: 160px;
  right: 91px;
  border: 1px solid #2c9db7;
  border-radius: 50%;
  padding: 1px 6px;
  z-index: 3;
  text-align: center;
}
.chlconnect {
  margin-bottom: -3rem !important;
}
.chldismiss {
  margin-bottom: -2rem !important;
}
.chnlcnt {
  margin-bottom: -1.5rem !important;
}
.buytext {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  margin-top: 1rem !important;
}
.buytext:focus {
  width: 100% !important;
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}

.buytext22 {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  height: 9rem !important;
}
.buytext22:focus {
  width: 100% !important;
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}
.buytext2 {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  height: 7.5rem;
}
.buytext2:focus {
  width: 100% !important;
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}
.userelip {
  width: 77%;
  white-space: nowrap;
  margin-left: 1rem !important;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (device-width: 320px) {
    width: 70% !important;
    margin-left: 0 !important;
  }
}
.menu_dropdown {
  color: #212529;
  display: block;
  width: 100%;
  i {
    margin-right: 1rem;
    font-size: 1rem;
    vertical-align: -17%;
  }
}
.prodrev {
  margin-top: 20px !important;
  width: 100% !important;
}
.buytext22 {
  border: 1px solid #ccc;
  padding: 8px 2px 2px 13px;

  border-radius: 5px;
}
.buytext22:focus {
  border-color: #66afe9;
  padding: 8px 2px 2px 13px;

  border-radius: 5px;
}
// <--------------------------------settingstab-------------------------------------------------->
.settingstext1 {
  width: 100% !important;

  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  margin-top: 1rem !important;
}
.settingstext1:focus {
  width: 100% !important;
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}
.faplus {
  margin-top: 2rem !important;
  color: #5cbcb1;
  font-size: xx-large;
  cursor: pointer !important;
}

.faminus {
  margin-top: 2rem !important;
  color: #e87d2e;
  font-size: xx-large;
  cursor: pointer !important;
}
// <--------------------------------------help/support----------------------------------------->
.chck1 {
  padding-top: 1.3rem !important;
  padding-left: 1.3rem !important;
}
.chck2 {
  padding-top: 1.5rem !important;
  padding-left: 1.3rem !important;
  text-align: end !important;
}
.agend table{
min-width: initial;
}

//--------------------------------------checkout--------------------------------------------->
.ordsum {
  border: 1px solid brown;
  width: 100%;
  height: 263px;
  background-color: cadetblue;
  border-radius: 4px;
}
.ordsum1 {
  background: #fff;
  border: 1px solid #ccc;
  height: 100px;
}
.chckout {
  height: 150px;
  border: 1px solid #ccc;
  background-color: #ffff;
}
.des {
  border: 1px solid #ccc;
  padding: 1rem;
  background: #ececec;
}

.detl-card {
  height: auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.15) !important;
}
.detl-card:hover {
  transform: scale(1.05);
}
.detl-img {
  width: 100%;
  height: 20rem;
}
.Yorder {
  margin-top: 15px;
  padding: 20px;
  border: 1px solid #dadada;
  background-color: #ececec;
  table {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  th {
    border-bottom: 1px solid #dadada;
    padding: 10px 0;
  }
  tr > td:nth-child(1) {
    text-align: left;
    color: #2d2d2a;
  }
  tr > td:nth-child(2) {
    text-align: right;
    color: #52ad9c;
  }
  td {
    border-bottom: 1px solid #dadada;
    padding: 1rem 0;
  }

  p {
    display: block;
    color: #888;
    margin: 0;
    padding-left: 25px;
  }
}
.Yorder > div {
  padding: 15px 0;
}

.listbill {
  background-color: #acd6e0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  h3 {
    color: #fff;
    padding: 1rem;
  }
  .faplus1 {
    color: #5cbcb1;
    font-size: xx-large;
    cursor: pointer !important;
  }
}
.listbill1 {
  .card {
    box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  }
  h4 {
    padding: 1rem;
  }
}
.copybill {
  margin-bottom: 1rem !important;
}
.cms-block {
  padding: 70px;
  p {
    font-size: 1.2rem !important;
  }
}
.addkey {
  width: 100% !important;
  .MuiFormControl-root {
    width: 100% !important;
  }
}
.stripepay {
  width: 100%;
  padding: 0rem 15rem;
  @media only screen and (min-width: 250px) and (max-width: 767px) {
    padding: 0rem !important;
  }
  @media only screen and (device-width: 768px) {
    padding: 0rem !important;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    padding: 0 10rem !important;
  }
}
.stripe1 {
  margin-top: 7rem;
}
.details {
  width: 130px;
  height: 130px;
  padding: 10px;
  border: 1px solid #b8b8b8;
  background-color: #fff;

  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    width: 100px !important;
    height: 100px !important;
    padding: 7px !important;
  }
}
.pad-tile {
  padding: 20px !important;
}
.edit-icon {
  position: absolute;
  z-index: 1;
  right: 1px;
  border: 1px solid rgba(243, 237, 237, 0);
  border-radius: 4%;
  padding: 0.2rem;
  // background-color: #d9d9d9;
}
.buytext22 {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  height: 3rem !important;
}

.g-top {
  .TopSearch input {
    @media only screen and (device-width: 1366px) {
      // width: 350px !important;
    }
    @media only screen and (max-width: 600px) {
      width: 258px !important;
      margin-left: 1.5rem !important;
    }
  }
  .TopSearch input:focus {
    @media only screen and (device-width: 1366px) {
      width: 575px !important;
      box-shadow: none !important;
    }
    @media only screen and (max-width: 600px) {
      width: 275px !important;
      box-shadow: none !important;
    }
  }
}
.text_elips {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.even-larger-badge {
  font-size: 0.85rem;
  @media only screen and (device-width: 2560px), (device-width: 1920px) {
    font-size: 1.2rem !important;
  }
}
.modal-dialog1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.Reorderdialog {
  max-width: 75% !important;
}
.buytext3 {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  height: 8rem;
}
.buytext22{
  height: 100px !important;
}
.buytext22::placeholder{
  color: #999 !important;font-weight:100 !important ;font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.back-but1 {
  margin-top: -4rem;
  float: left;
}
.ch-btn-blue1 {
  // margin-top: -5rem;
  color: white;
}
.buytext4 {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 2px 2px 13px;
  height: 12rem;
}
.buytext4:focus {
  width: 100% !important;
  padding: 8px 2px 2px 13px;
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 14px -2px rgba(0, 0, 0, 0.75);
}
.rightEdit {
  right: 2rem;
  top: 1rem;
  z-index: 4;
  position: absolute;
}
#nav-bar {
  z-index: 9999 !important;
}
.cropper-modal {
  background-color: #fff !important;
}
.cmpnyvideo-wrap{
  width:100%;height:400px;
}
.modal > #previe {
  // height: 99%;
  overflow-y: hidden;
  margin:auto;
  max-width: 50%;
  max-height: 100%;
  overflow-x: hidden;
  // .modal-dialog{

  // }
}
.signuptophead {
  @media all and (min-width: 319px) and (max-width: 599px) {
    margin-top: 5rem !important;
    height: 350px !important;
  }
  @media all and (device-width: 768px) {
    margin-top: 5rem !important;
  }
}
.rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  background-color: #2c9db7;
  h1 {
    color: #fff;
  }
}
.package_column {
  display: flex;
  align-items: center;
  // flex-direction: column;
  // justify-content: center;
  margin-bottom: 30px;
}
.package_card .card {
  margin-bottom: 0 !important;
}
.center {
  line-height: 215px;
  height: 215px;
  // border: 3px solid green;
  text-align: center;
}
.card-overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
}
.botEve {
  border-left: 3px solid #f8f9fe;
}
.Evedat {
  font-size: 6rem;
  font-weight: 200;
}
.headeve {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 95%;
}
.card-overlaydash {
  clip-path: polygon(45% 0, 100% 0, 100% 100%, 15% 100%);
  // background: rgba(0, 0, 0, 0.5);
  background: url(../../img/common/eventsignup.jpg);
  width: 100%;
  height: 478px;
  position: absolute;
  top: 0;
}
.card-overlaydashwe {
  clip-path: polygon(45% 0, 100% 0, 100% 100%, 15% 100%);
  // background: rgba(0, 0, 0, 0.5);
  background: url(../../img/common/eventsignup.jpg);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
#upEve {
  z-index: 2;
}
.shadecard {
  box-shadow: -3px 2px 8px #aaaaaa !important;
}
// .........................About...............
.about-wrapper{
  padding:30px 0;
}
.about-wrapper .container{
  max-width:80% !important;
}
.sub-about h3{
  font-size:25px;color:#2d0b4e;
}
.sub-about p{
  font-size: 17px;
  ;
}
.founders{
  padding-top: 40px;
}
.founders h2{
  text-align: center;font-size:32px;color:#2A2A2A;position: relative;
}
.founders h2:after{
  position: absolute;content:'';width:5%;height:3px;background-color: #2a2a2a;left:0;right:0;margin:auto;bottom:0;
}
.founder-card figure{
  position:relative;width:250px;height:250px;margin:auto;margin-bottom: 15px;
}
.founder-card figure img{
  position: absolute;max-width:100%;max-height:100%;border-radius: 100%;left:0;right:0;margin:auto;
}
.activity .pl-0{
  padding-left: 0;
}
.activity .pr-0{
  padding-right: 0;
}
.founder-card{
  margin-top:20px;text-align: center;
}
.founder-card p{
  font-size:14px;text-align: left;
}
.activity a:hover{
  background-color: #2D0B4E;color:#fff;
}
.founder-card h4{
  color:#0F0F0F;font-size: 20px;font-weight: initial;margin-bottom: 0;
}
.founder-card h5{
  color:#0F0F0F;font-size: 16px;font-weight: initial;
}
.founder-card a img{
  max-width:11%;margin:15px 0;
}
.activity-right img{
  max-width: 100%;right:-15px;position: relative;
}
.activity-left{
  color:#fff;
}
.activity-left{
  padding:15px;
}
.activity-left p{
  font-size: 17px;
}
.activity-card{
  margin-top:20px;
}
.activity-left h5{
  color:#fff;font-weight:bold;
}
.activity-left h3{
  color:#fff;font-size: 18px;
}
.activity a{
  cursor:pointer;transition:.3s all;background-color: #fff;padding:10px 30px;margin-bottom: 20px;display: inline-block;margin-left: 30px;color:#333;
}
.res-wrapper{
  display: none;
}



// ----------------------------------NewsSection------------------------------
.news-bg {
  width: 1903px;
  height: 337px;
  object-fit: cover;
  object-position: 50% 50%;
  position: relative;
  .news-blue {
    position: absolute;
    width: 100%;
    float: right;
    top: 0;
    img {
      top: -55px;
      position: absolute;
      right: 12%;
    }
    h6 {
      top: 3px;
      position: absolute;
      right: 21%;
      color: #fff;
      font-size: 45px;
      font-weight: bold;
    }
  }
}
.ch-announce {
  background-color: #0100ffba;
  width: 100%;
  height: 100%;
  padding: 5% 5%;
  float: left;
  h5 {
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 32px;
  }
  .ch-details {
    width: 100%;
    float: left;
    margin: 27px 0px;
    span {
      width: 100%;
      float: left;
      color: #fafafa;
      font-size: 17px;
    }
    a {
      width: 100%;
      float: left;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }
}

//SUBSCRIPTION

.viewBtn {
  @media screen and(max-width:400px) {
    margin-top: 0 !important;
  }
}

.dashboard_search{
  justify-content: end;
  gap: 1rem;
}

//SUGGESTION

.suggestTitle h1 {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .suggestTitle h1 {
    // font-size: 23px;
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 770px) {
  .suggestTitle {
    padding: 0;
  }
  .suggestTitle h1 {
    font-size: 23px;
  }

  .list-tab {
    justify-content: space-between;
  }
  //For events
  .list-tab .nav-link {
    padding: 10px !important;
  }
  .list-adtab {
    margin-right: 0 !important;
  }

  .list-tab .list-ven {
    width: 20% !important;
  }

  .list-tab .list-ven .row {
    text-align: center;
    margin: 0px !important;
    padding: 0 I !important;
  }

  .listCompany {
    margin-top: 0 !important;
    padding: 0 !important;
  }

  .listCompany .awards_thumb {
    padding: 0 !important;
  }

  .productList {
    margin-left: 0 !important;
  }
}

.list-tab .list-ven {
  @media screen and (max-width: 600px) {
    margin-right: 0px !important;
  }
}

//Event List of buyers
@media screen and (max-width: 800px) {
  .eventBuyerListing .badge {
    font-size: 10px !important;
  }
  .eventBuyerListing .mobi-res {
    text-align: center;
  }
}

.card.card-stats .card-body {
  padding: 1rem 1rem;
}
.card-profile-stats > div {
  margin-right: 0 !important;
}

// ..........mobile dashboard..........
@media (min-width: 370px) and (max-width: 600px) {
  .MuiStep-horizontal {
    padding-right: 0 !important;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 12px !important;
  }
  .header-body .text-lead.text-white {
    display: none;
  }
}
@media (max-width: 768px) {
  .paymentpopup{
    min-width: 400px !important;margin: auto;
  }
  .dashslide-right{
    display: none;
  }
 .news-img{
  width: 324px;
    height: 230px;
 }
  .down-exhibitor{
    left: initial;
  }
  .exhibitor-guide{
    text-align: center;margin: auto;
  }
  .exhibitor-guide img{
    max-width: 30%;
    max-height: 30%;top: initial;left: initial;
  }
  .des-profie{
    display: none;
  }
  #eHead{
    text-align: center !important;
  }
  .tempevent-modal{
    width: 628px !important;
  }
  .temp-edit{
    right: -62px;
  }
  .temp-cont{
    bottom: 146px;left: -8px;width: 100px;height: 100px;
  }
  .temp-logo{
    width: 127px;height: 127px;
  }
  .res-wrapper{
    display: block;
  }
  .des-wrapper{
    display: none;
  }
  .eventsubs-tabs{
    width: 100%;
  }
  .des-close{
    display: none;
  }
  .channel-logo{
    display: none;
  }
  .sharemodal-left h2{
    font-size: 21px;
  }
  .sharemodal-left p{
    font-size: 18px;
  }
  .sharemodal.modal-dialog{
    max-width: 500px !important;
  }
  .res-close{
    display: block;
  }
  .tempeft-image{
    width: 94%;height: 90%;margin: auto;text-align: center;
  }
  .res-pad{
    padding: 0;
  }
  .video-wrap {
    text-align: center;
    margin: auto;
    float: none;
  }
  .modal-dialog.modal-dialog-centered.companypic-zoom{
    max-width: 300px !important;margin: auto;
  }
  .companypic-zoom .imgprev{
width: 250px !important;height: 200px !important;position: relative;margin-left: 25px;
  }
  .meeting-dialog .modal-body{
    max-height: 65vh;overflow-y: scroll;
  }
  .meeting-table{
    width:100% !important;
  }
  .youpad iframe {
    width: 400px;
    height: 300px;
  }
  .user-card.pt4 {
    padding-top: 1rem !important;
  }
  .card-body {
    padding: 10px !important;
  }
  #Events .event-card {
    padding: 0 !important;
  }
  .productdetImg {
    height: 30vh !important;
  }
  .otp-verify {
    padding-left: 0;
    padding-right: 0;
  }
  .dashslide-left {
    width: 100%;
  }
  .dashslide-right {
    width: 100%;
  }

  .verify-box input {
    width: 16px !important;
  }
  .header.signup-header-bg.signuptophead {
    margin-top: 75px !important;
  }

  .header-body .py-4 {
    padding: 10px 0 !important;
    margin: 0px !important;
  }

  .cust-pad {
    padding: 20px !important;
  }
  .signupeventpack .rotated {
    height: auto !important;
    line-height: 40px;
    transform: initial;
    writing-mode: initial;
  }
  .signupeventpack .rotated h1 {
    padding-top: 10px;
  }
  .signupeventpack .card {
    left: 0 !important;
  }
  .res-pad p {
    font-size: 12px !important;
  }
  .card-body h5 {
    font-size: 12px !important;
  }
  // .mobile-head{
  //   margin-top:-2.5rem !important;
  // }
  .product .card-wrapper {
    display: none;
  }
  .cust-pad {
    padding: 20px !important;
  }
  .card-profile-stats > div {
    padding: 3px;
  }
  .header-body button {
    float: right;
    margin-top: 0;
  }
 
  #lstnews {
    max-height: 20rem !important;
    padding-bottom: 20px !important;
  }
  .scrollbar-container .media-comment {
    margin-top: 5px !important;
  }
  .card-overlaydash {
    clip-path: initial;
  }
  #upEve h1 {
    color: #fff;
    position: relative;
    z-index: 1;
  }
  h1.Evedat1 {
    font-size: 3rem !important;
    color: #000 !important;
  }
  .timeline-content {
    margin-left: 20px !important;
  }
  .botEve {
    padding: 0 !important;
  }
  
  .mobile-event {
    display: block !important;
  }
  #eHead {
    color: #000 !important;
  }
  .late-buyers {
    min-height: 130px !important;
  }
  .botEve p {
    font-size: 13px !important;
  }
  .botEve p {
    font-size: 13px !important;
  }
  .botEve div {
    display: none !important;
  }
  .media-comment .text-muted {
    font-size: 12px;
  }
  .media-comment .NewsContainer {
    display: none;
  }
  .card.card-stats {
    margin-bottom: 15px !important;
  }
  .scrollbar-container {
    margin-bottom: 20px;
  }
  .navbar-collapse .navbar-search {
    display: none !important;
  }
  .CircularProgressbar {
    width: 50% !important;
  }
  .scrollbar-container.ps {
    text-align: center;
  }
  .timeline-one-side .timeline-content {
    text-align: left;
  }
  .mobi-res5 {
    margin-bottom: 15px;
  }
  .list-tab #Event .list-adtab {
    width: 100% !important;
    height: initial !important;
    padding-top: 10px !important;
  }
  .list-adtab .nav-link {
    height: initial !important;
  }
  .card-overlaydashwe {
    clip-path: initial !important;
  }

}
@media (min-width:300px) and (max-width: 499px) { 
  .tempevent-modal{
    width: 330px !important;margin: auto;
  }
.temp-cont{
  bottom: 53px;left: -12px;width: 70px;height: 70px;
}
.temp-logo{
  width: 60px;height: 60px;
}
.temp-edit{
  right: -32px;
}
.temp-para{
  bottom: -3px;left: 23px;font-size: 11px;font-weight: 600;
}

.tempevent-modal h2{
  font-size: 17px;
}
}
@media (min-width:500px) and (max-width: 680px) { 
  .paymentpopup{
    min-width: 400px !important;
  }
  .tempevent-modal{
    width: 448px !important;margin: auto;
  }
.temp-cont{
  bottom: 92px;left: -8px;width: 80px;height: 80px;
}
.temp-logo{
  width: 90px;height: 87px;
}
.temp-edit{
  right: -43px;
}
.temp-para{
  bottom: 15px;left: 37px;
}
.tempevent-modal h2{
  font-size: 17px;
}
}
@media (min-width: 768px) and (max-width: 991px) {
  .tempevent-modal{
    width: 628px !important;
  }
  .exhibitor-guide{
    text-align: center;
  }
  .down-exhibitor{
    left: initial;
  }
  .news-img{
    width: 324px;
    height: 230px;
  }
  .exhibitor-guide img{
    max-width: 30%;
    max-height: 30%;top: initial;left: initial;
  }
  .paymentpopup{
    min-width: 650px !important;
  }
  .des-profie{
    display: none;
  }
  .temp-para{
    left: 55px;

  }
  .tempevent-modal h2{
    font-size: 20px;
  }
  .res-wrapper{
    display: block;
  }
  .des-wrapper{
    display: none;
  }
  .header.signup-header-bg.signuptophead {
    margin-top: 75px !important;
  }
  .modal-dialog.modal-dialog-centered.companypic-zoom{
    max-width: 500px !important;
  }
  .companypic-zoom .imgprev{
width: 400px !important;height: 300px !important;position: relative;margin-left: 25px;
  }
  .meeting-table{
    width:100% !important
  }
  .productdetImg {
    height: 40vh !important;
  }
  .user-card.pt4 {
    padding-top: 1rem !important;
  }
  .otp-verify {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .cust-pad {
    padding: 20px !important;
  }
  .signupeventpack .rotated {
    height: auto !important;
    line-height: 40px;
    transform: initial;
    writing-mode: initial;
  }
  .signupeventpack .card {
    left: 0 !important;
  }
  .signupeventpack .rotated h1 {
    padding-top: 10px;
  }
  .mobile-event {
    display: block !important;
  }
  #Event .list-adtab {
    width: 19% !important;
  }
  #lstnews {
    max-height: 34rem !important;
    padding-bottom: 20px !important;
  }
  .NewsContainer {
    max-height: 3rem !important;
  }
  .CircularProgressbar {
    width: 50% !important;
  }
  .scrollbar-container.ps {
    text-align: center;
  }
  .timeline-one-side .timeline-content {
    text-align: left;
  }
  .late-buyers {
    min-height: 150px !important;
  }
  .prof-001 {
    max-width: initial !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .card-profile-stats > div {
    padding: 4px !important;
  }
  .ct-example .mobi-res3 {
    margin-top: 10px;
  }
  .ct-example .mobi-res {
    text-align: center;
  }
  .sharemodal.modal-dialog{
    max-width: 700px !important;
  }
  .des-close{
    display: none;
  }
  .res-close{
    display: block;
  }
  .sharemodal-left h2{
    font-size: 20px;
  }
  .sharemodal-left p{
    font-size: 18px;
  }
  
}
.wishcount {
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 1;
  color: #e44848;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  #product-detail .key-card {
    width: 150px !important;
  }
  .list-image{
    width: 130px;
    height: 130px;
  }
  .late-buyers{
    min-height: 180px;
  }
  .news-img{
    width: 340px;
    height: 270px;
  }
}
.presentslide-image {
  position: relative;
  width: 100%;
  height: 300px;
  padding: 2px;
}
.presentslide-image img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.awards-wrapper {
  background-color: #fff;
  padding: 30px;
  position: relative;
  z-index: 1;
}
.cat-list .item-container span {
  font-weight: bold;padding-right: 5px;
}
.cat-list .item-container p {
  display: inline;
  font-size: 16px;
}
.awards-wrapper .item-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  padding: 20px;
}
.awards-wrapper select {
  padding: 10px;
}
.video-responsive {
  position: relative;
  width: 100%;
  height: 300px;
  margin-top: 15px;
}
.video-responsive iframe {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.filter-container {
  margin-bottom: 20px;
}
.signupeventpack .card {
  min-height: 280px;
  left: 10px;
}
.signupeventpack .card ul {
  padding: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .tempevent-modal{
    width: 945px !important;
  }
  .news-img{
    width: 277px;
    height: 218px 
  }
  .temp-cont{
  left: 42px;
  bottom: 108px;width: 100px;height: 100px;
  }
  .tempevent-modal h2{
    font-size: 19px;
  }
  .temp-logo{
    width: 109px;height: 112px;
  }
  .temp-para{
    bottom: 35px;  left: 44px;

  }
  .sharemodal.modal-dialog{
    max-width: 950px !important;
  }
  .otp-verify {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .dash-share{
    width: 58%;margin-top: 5px;position: relative;left: 30px;display: block;
  }
  .dummy-space{
    width: 800px !important;
  }
  .meeting-table{
    width:100 !important;
  }
  .productdetImg {
    height: 45vh !important;
  }
  
}

@media (max-width: 283px) {
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    display: none;
  }
  .main-content .signupcontainer {
    margin-top: -27rem !important;
  }
  .signupcontainer .card-body {
    padding: 10px !important;
  }
  .verify-box {
    padding: 10px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .selectprof {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  button.MuiIconButton-root {
    display: none !important;
  }
  .telecard .react-tel-input,
  .otpphone .react-tel-input {
    width: 100% !important;
  }
  .mob-signhead {
    display: block !important;
    font-size: 18px;
    margin-bottom: 15px !important;
  }
  .header-body .text-lead.text-white {
    display: none;
  }
}
@media (max-width:575px){
  .activity-right img{
    max-width:60%;
  }
}
@media (max-width: 480px) {
  .tempevent-modal{
    width: 300px !important;
  }
  .temp-cont{
    width: 50px;height: 50px;bottom: 53px;
  }
  .temp-para{
    bottom: -13px;
  }
  .icon-tag2 h3 {
    font-size: 13px !important;
  }
  .productdetImg {
    height: 30vh !important;
  }
  .activity-right img{
    max-width:80%;
  }
}

.prdimgdia {
  margin: auto;
}
.react-tel-input .form-control {
  width: 100% !important;
}

button {
  cursor: pointer !important;
}

.fas {
  cursor: pointer !important;
}
@media (max-width:991px){
  
  .about-wrapper .activity .pl-0{
    padding-left: 15px !important;
  }
  .about-wrapper .activity .pr-0{
    padding-right: 15px !important;
  }
  .founder-card figure{
    width:200px;height:200px;
  }
  }
  
  .TopSearch input:focus .ant-select-dropdown{
   display: block !important; background: red !important;
  }
  
  @media(max-width:480px){
    .signuptophead{
      height: 180px !important;
    }
    .text-lead{
      display: none;
    }
    .header-body h1{
      padding-top: 20px;
    }
  }

  @media (max-width:280px){
    .tempevent-modal{
      width: 265px !important;
    }
    .tempevent-modal h2{
      font-size: 12px;
    }
    .tempevent-modal h4{
      font-size: 11px;
    }
    .temp-cont{
      width: 35px;height: 35px;bottom: 49px;left: -3px;
    }
    .temp-logo{
      width: 43px;height: 43px;
    }
    .temp-para{
      bottom: -8px;font-size: 10px;left: 20px;
    }
    .temp-edit{
      right: -25px;
    }
  }

  .temp-para span{
    font-weight: 700;
  }
  #cmp-bac br{
    display: none !important;
  }