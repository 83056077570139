/*--------------------------Global Custom  Styles -------------------------------*/
.nav-pills .nav-link {
    color: #2c9db7 !important;
}
.nav-pills .nav-link.active {
    color: white !important;
}
.nav-pills .nav-link.active:hover {
    color: white !important;
}
.nav-pills .nav-link:hover{
    color: #2c9db7 !important;
}
.nav-span{
    position: relative;
    left: -2rem;
}
.react-tel-input .country-list{
    text-align: left !important;
    
}
.react-tel-input .form-control{
    height: 3.5rem !important;
    font-size: 20px !important;
    color: #000000bf !important;

    @media only screen and (max-width:800px)
    {
        width: 100% !important;
    }
}
.MuiIconButton-colorSecondary{
    color:darkgray !important;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #2c9db7 !important;
}
.active .tat{
    color: white;
}
.tat{
    color: #2c9db7;
}
.nav-pills .nav-link.active{
    background-color: #2c9db7;

}
.btn-primary {
    color: #fff;
    background-color: #2c9db7 !important;
    border-color: #2c9db7 !important;
}
.MuiStepIcon-root.MuiStepIcon-active {

    color: #2c9db7 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
    color:#2c9db7 !important;
}

.plus{
    font-size: xx-large !important;
    cursor: pointer !important;
    text-rendering: auto !important;
    -webkit-font-smoothing: antialiased !important;
    box-sizing: border-box !important;
}

.plus1{
    font-size: xx-large !important;
    cursor: pointer !important;
    text-rendering: auto !important;
    -webkit-font-smoothing: antialiased !important;
    box-sizing: border-box !important;
    color: brown;
}
.awar1{
    padding-top: 3rem;
}
.custom-control-input:checked ~ .custom-control-label::before  {background-color: #2c9db7 !important}


    .Dboard {
        border: 1px solid red;
        width: 100%;
        height: 5rem;
        border-radius: 10px;
        margin-top: 2rem !important;
    }
    .Dashboard1{
        margin-top: 2rem !important;
    }
    .Tpitch{
        width: 100% !important;
        height: 10rem !important;
        margin-top: 3rem !important;
    }
.multi-preview img{
    max-width: 10rem !important;
    padding: 1rem !important;
    
}
.preview-file{
    input{
        color: brown;
    }
}

.btn-danger{
    color: #fff;
    background-color: #2c9db7;
    border-color: #f5365c;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.split{

    border-left: 1px solid red !important;

}
   .VATN{
     input{
    border-left: 1px solid  rgba(0, 0, 0, 0.54) !important;
    color: black !important;
    padding-left: 8px !important;
}
}

.CRN{
    input{
        border-left: 1px solid  rgba(0, 0, 0, 0.54) !important;
   color: black !important;
   padding-left: 8px !important;
}
}

//------------------ Bg Colors---------------

.bg-red{
    background-color: #f5365c !important;
}
.bg-green{
    background-color:#2dce89 !important
}
.bg-blue{
    background-color: #2c9db7 !important;
}
.bg-blue{
    background-color: #808080ed !important;
}
.fg-blue{
    color: #2c9db7 !important;
}
.fg-green{
    color:#2dce89 !important
}
.btn-outline-blue {
    color: #2c9db7;
    background-color: transparent;
    background-image: none;
    border-color: #2c9db7;
}
.btn-outline-blue:hover {
    color: #fff;
    background-color: #2c9db7;

    border-color: #2c9db7;
}
.pointer{
cursor: pointer;
}
.isConfidential{
    -webkit-filter: blur(10px);
    filter: blur(10px);
    cursor: pointer;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
}

